<!--
	Currently this is only meant for TopBar because TopBar requires some custom functionalities

	props: [
		{
			label: 'First item',
			callback: () => doSomething(),
			href: '#url',
			title: 'this hover text is optional'
		},
		{
			isSeparator: true
		},
		{
			label: 'Disabled item (no callback or href)'
		},
	]
-->

<template>
	<div class="ContextMenu" :style="css">
		<template v-for="(link, index) of filteredLinks">
			<div :key="`ContextMenu-${index}`" v-if="link.isSeparator" class="divider"></div>
			<!-- divider -->
			<a
				:href="link.href"
				:title="link.title"
				:key="`ContextMenu-${index}`"
				:target="link.targetBlank ? '_blank' : ''"
				v-else
				class="ContextMenu-item"
				:class="{ disabled: isLinkDisabled(link), [link.class]: !!link.class }"
				@click="itemClicked(link)"
			>{{link.label}}
				<component v-if="link.decorationComponent"
						   :is="link.decorationComponent"></component>
			</a>
			<!-- normal -->
		</template>
		<slot></slot>
	</div>
</template>
<script>
import vueStore from '../es6/store'
import { dismissableMixin } from '@resqclub/resq-components/mixins'

export default {
	name: 'ContextMenu',
	mixins: [dismissableMixin],
	props: {
		links: {
			type: Array, // [{ label: 'Click me', callback: () => doSomething() }]
			default: () => [],
		},
		css: String,
		onClose: Function
	},
	data: () => ({}),
	// mounted() {
	// 	window.addEventListener('click', () => {
	// 		this.$el.remove()
	// 		this.$destroy()
	// 	})
	// },
	computed: {
		filteredLinks() {
			return this.links.filter(link => {
				if (!link) {
					return false
				}

				if (link.showWhen) {
					return link.showWhen(vueStore.state, vueStore.getters)
				}

				return true
			})
		}
	},
	methods: {
		isLinkDisabled(link) {
			return !link.callback && !link.href
		},
		itemClicked(link) {
			this.$emit('close')
			if (link.callback) {
				link.callback()
			}

			if (!this.isLinkDisabled(link)) {
				this.$destroy()
			}
		},
	},
	components: {},
	beforeDestroy() {
		this.onClose && this.onClose()
	},
}
</script>

<style lang="stylus">
</style>
<style lang="stylus" scoped>
.ContextMenu {
	display: inline-block;
	// how do to intelligent width?
	position: absolute;
	min-width: 100px;
	max-width: 100vw;
	width: auto;
	background: white;
	border-radius: 3px;
	box-shadow: 0px 1px 13px 1px rgba(0, 0, 0, 0.13);
	padding: 10px 0;
	font-size: 14px;
	z-index: 100;

	.ContextMenu-item {
		text-decoration: none;
		padding: 5px 20px;
		padding-top: 8px;
		display: block;
		color: #333 !important;

		position relative

		&:not(.disabled) {
			cursor: pointer;

			&:hover {
				background: #f7f7f7;
			}
		}

		&.disabled {
			color: gray !important;
		}

		&:hover {
		}
	}

	.divider {
		background: #e8e8e8;
		height: 1px;
		margin-top: 5px;
		margin-bottom: 5px;
	}
}

@media screen and (max-width: 850px) {
	.ContextMenu {
		width: 100%;
		background: #00b999;
		padding: 0 0;
		padding-left: 20px

		border-top: 1px solid #00b38c

		border-radius: 0
		box-shadow: 0 3px 5px -1px rgba(29, 154, 127, 0.57);

		.ContextMenu-item {
			color: white !important;
			font-weight: 400;
			padding: 10px 0;

			&:not(.disabled):hover {
				background: transparent;
			}
		}
		.ContextMenu-item:not(:first-child) {
			border-top: 1px solid #00b38c;
		}

		position: fixed;
		left: 0
		right: 0
		top: 60px;
	}

	.divider {
		display: none;
	}
}
</style>
