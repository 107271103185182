/* eslint-disable import/order -- didn't want to risk breaking something by reordering here */
import vueStore from './store'
window.vueStore = vueStore

import './firebase'
import { OfferTimesInputs } from './offerTimesInputs'
import OfferTime from './offerTime'
import TagProposer from './TagProposer'
import TopBar, { createVueTopBar } from '../components/TopBar.vue'
import NewButton from '../components/NewButton.vue'
import CreateOfferFromTemplate, { openCreateOfferFromTemplatePopup } from '../components/CreateOfferFromTemplate.vue'
import { setOrderAlerts, playOrderAlert, stopOrderAlert, isOrderAlertsOn, initOrderAlertSound } from './orderAlert'
import './routeManager'
import * as redom from 'redom'
import { TimeValidator } from './timeValidator'
import vintageUpdaterBasedOnFirebase from './vintageUpdaterBasedOnFirebase'
import { initRouteManager } from './routeManager'
import { intercomInit } from './intercom'
import MapLocationSelector from '../../common/components/MapLocationSelector'
import { getProviderData, setProviderData, isDomNodeConnected } from './util'

import { listenToChangesInProviderCookie } from '../../common/modules/cookieChangeListeners'
listenToChangesInProviderCookie()

import _ from 'lodash'

import Vue from 'vue'

import { start as startActivityTracker } from './activityTracker'
startActivityTracker()

const vintageUpdaterBasedOnFirebaseClass = Vue.extend(vintageUpdaterBasedOnFirebase)
new vintageUpdaterBasedOnFirebaseClass()

import '../../common/js/external/ResQ_Brother.js'

window.OfferTimesInputs = OfferTimesInputs
window.OfferTime = OfferTime
window.separator = ':'
window.TagProposer = TagProposer
window.TopBar = TopBar
window.redom = redom
window.Vue = Vue
window.NewButton = NewButton
window._ = _

window.intercomInit = intercomInit

window.createVueTopBar = createVueTopBar
window.TimeValidator = TimeValidator
window.openCreateOfferFromTemplatePopup = openCreateOfferFromTemplatePopup
window.initRouteManager = initRouteManager
window.MapLocationSelector = MapLocationSelector

window.setOrderAlerts = setOrderAlerts
window.playOrderAlert = playOrderAlert
window.stopOrderAlert = stopOrderAlert
window.isOrderAlertsOn = isOrderAlertsOn
window.initOrderAlertSound = initOrderAlertSound

// These are in window to make handy development work possible
// ...and to make them accessible from pre-es6 .js files
window.getProviderData = getProviderData
window.setProviderData = setProviderData
window.isDomNodeConnected = isDomNodeConnected

// Update window size in store

window.addEventListener('resize', (ev) => {
    const newSize = { width: window.innerWidth, height: window.innerHeight }

    vueStore.commit('setWindowSize', newSize)
})

window.initMap = function() {
    waitNoMore.googleMaps()
}

// setTimeout(() => {
// 	window.marko = new Vue({
// 		render: h =>
// 			h(CreateOfferFromTemplate),
// 	}).$mount().$el
// 	document.querySelector('#content').prepend(marko)
// }, 100)

// Debug utility
// d(12) =>
window.d = function(time) {
    const pad2 = n => String(n).length > 1 ? n : '0' + n
    const n = Number(time)
    if (!isNaN(n)) {
        time = pad2(n) + ':00'
    }
    const [_, h, m] = time.match(/(\d\d):(\d\d)/)

    const date = new Date()
    date.setHours(h)
    date.setMinutes(m)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
}
