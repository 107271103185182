<template>
	<div class="InsightsBestOfResqMeter">
		<img
			v-if="isBestOf"
			class="bestOfResqIcon"
			src="/provider/img/icon-bor_active.svg"
			alt="Best Of ResQ"
		/>
		<img
			v-else
			class="bestOfResqIcon"
			src="/provider/img/icon-bor_grey.svg"
			alt="Best Of ResQ"
		/>

		<div class="progressBar">
			<div
				ref="progress"
				:style="`width: ${progressPercentage}%`"
				class="progressBarProgress"
			></div>
		</div>
		<div class="progressDescription">
			<Lang id="insights.bestOf.description">
				<span slot="0">{{ progressPercentage }}%</span>
				<b slot="1">{{ lang.insights.bestOf.linkText }}</b>
			</Lang>
		</div>
	</div>
</template>

<script>
import Lang from './Lang.vue'

export default {
	name: 'InsightsBestOfResqMeter',
	data: () => ({
		lang: window.lang,
		isBestOf: false, // will be overridden when we get profile info
		progressPercentage: 0,
	}),
	computed: {},
	mounted() {
		// profile might not be there when this component is created
		window.wait.profile.then(profile => {
			this.isBestOf = profile.isBestOf

			// This same formula is in server side.
			let providerScore = profile.ratingScore

			this.progressPercentage = Math.round(
				(100 * providerScore) / use.env.PROVIDER_SCORE_THRESHOLD_FOR_BEST_OF
			)

			if (this.progressPercentage < 0) {
				this.progressPercentage = 0
			} else if (this.progressPercentage > 100) {
				this.progressPercentage = 100
			}
		})
	},
	methods: {},
	components: { Lang },
}
</script>

<style lang="stylus" scoped>
.InsightsBestOfResqMeter {
	background: #ececec
	padding: 14px 27px
	height: 70px
	border-radius: 5px
	margin-left: 10px
	display: inline-block
}

.bestOfResqIcon {
	float: right
	width: 22px
	margin-left: 20px
	position: relative
	top: -2px
}

.progressBar {
	display: inline-block
	$height = 12px
	width: 200px
	height: $height
	background: white
	border-radius: ($height / 2)
	position: relative
	overflow: hidden

	> div {
		width: 0px // this will be overridden
		height: 100%
		background: #1bcbac
	}
}

.progressDescription {
	margin-top: 5px
	font-size: 12px
}

@media screen and (max-width: 1100px) {
	.InsightsBestOfResqMeter {
		display: block
		width: 100%
		margin-left: 0
		padding: 14px 0
		text-align: left
		background: transparent
	}

	.bestOfResqIcon {
		width: 30px
		top: -6px
	}

	.progressBar {
		width: calc(90vw - 58px)
		background: #ececec
	}
}
</style>
