//
//
//
//
//
//
//
//
//
//
//
//
//

import Store from '../es6/store'
export default {
    name: 'TopBarTab',
    props: {
        data: Object // { label, callback, selected, href, numberBadgeKey, class, extraHtml }
    },
    data: () => ({
    }),
    computed: {
        isActive() {
            return '#' + Store.state.currentPage === this.data.href
        },
        numberBadgeValue() {
            return Store.state[this.data.numberBadgeKey]
        },
        showNumberBadge() {
            return this.data.numberBadgeKey && this.numberBadgeValue > 0
        }
    },
    methods: {
    },
    components: {
    }
}
