//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TopBarTab from './TopBarTab.vue'
import { toggleDropDown } from './TopBarContextMenu.vue'
import MenuDown from 'vue-material-design-icons/MenuDown.vue'
import Menu from 'vue-material-design-icons/Menu.vue'
import ArrowBack from 'vue-material-design-icons/ArrowLeft.vue'
import CloseIcon from 'vue-material-design-icons/Close.vue'
import Vue from 'vue'
import vueStore from '../es6/store'

export default {
	name: 'TopBar',
	props: {},
	data: () => {
    const languageCode = window.getLanguage();
    const newOrdersViewUrl = `${use.env.NEW_PARTNER_APP_URL_BASE}/${languageCode}/orders`
    const ordersHref = !!use.profile.useNewWebApp ? newOrdersViewUrl : '#orders'

    let tabs = [
			!use.profile.isModel ? {
				label: lang.p.tab.allOffers,
				href: '#offers',
				selected: true,
			} : null,
			{
				label: lang.p.tab.templates,
				href: '#templates',
			},
			!use.profile.isModel ? {
				label: lang.p.tab.orders,
				href: ordersHref,
				numberBadgeKey: 'unfetchedOrdersCount',
				extraHtml:
					`<span class="audioOffElement" title="Audio muted until the page is clicked">🔇</span>`
			} : null,
		].filter(Boolean)

		return {
			tabs,
			profile: null,
			burgerMenuVisible: false,

			namePostFix: '',
		}
	},
	created() {
		wait.profile.then(profile => {
			this.profile = profile

			wait.env.then(env => {
				if (env.admin) {
					let modelString = this.profile.isModel ? 'model ' : ''
					this.namePostFix = ` (${modelString}${profile.id}) \n ADMIN `
				}
			})
		})

		this.updateBodyPaddingTop()
	},
	computed: {
		showMainNavigation() {
			return !!this.tabs.find(tab => tab.href === '#' + vueStore.state.currentPage)
		},
		page() {
			return vueStore.state.currentRoute && vueStore.state.currentRoute.title
		},
		name() {
			let profile = this.profile
			let name = ''
			if (profile) {
				if (profile.isModel && profile.enterprise) {
					name = profile.enterprise.name
				} else {
					name = profile.name
				}
			}
			return name + this.namePostFix
		},
		showAudioFailure() {
			// In computed we must call getter of both values
			let able = vueStore.state.ableToPlayAudio
			let fail = vueStore.state.audioPlayingFailed
			return !able && fail
		},
	},
	watch: {
		showMainNavigation(show) {
			this.updateBodyPaddingTop()
		},
	},
	methods: {
		toggleDropDown,
		updateBodyPaddingTop() {
			if (this.showMainNavigation) {
				document.body.style.paddingTop = '120px'
			} else {
				document.body.style.paddingTop = '60px'
			}
		}
	},
	components: {
		TopBarTab,
		MenuDown,
		Menu,
		ArrowBack,
		CloseIcon
	},
}

let topBar = null

export function createVueTopBar() {
	let wrapper = new Vue({
		render: h => h(window.TopBar),
	}).$mount('.topBarPlaceholder')

	topBar = wrapper.$children[0]
}

