<template>
	<div class="InsightsDetailsCell">
		<div v-if="reasonGiven" class="reason">
			<h1>{{ reasonTitle }}</h1>
			<p>{{ reasonDescriptions }}</p>
		</div>
		<p v-if="messageGiven" class="message">{{ data.message }}</p>
	</div>
</template>

<script>
import { Lang } from '@resqclub/resq-components'
import _ from 'lodash'

export default {
	props: ['data'],
	computed: {
		reasonGiven: function() {
			return !!this.data.reason
		},
		messageGiven: function() {
			return !!this.data.message
		},
		isProblem: function() {
			return this.data.rating === 'problem'
		},
		reasonTitle: function() {
			return _.get(window.lang, this.reasonTitleKey)
		},
		reasonTitleKey: function() {
			if (!this.isProblem) {
				return 'review.reason.title.' + this.data.rating
			} else {
				return 'problem.reason.title'
			}
		},
		reasonDescriptions: function() {
			const getDescription = (reason, rating) => {
				let result
				try {
					if (this.isProblem) {
						result = window.lang.problem.reason[reason]
					} else {
						result = window.lang.review.reason[reason][rating]
					}
				} catch (e) {
					result = '[' + reason + ']'
				}
				if (!result) {
					result = '[' + reason + ']'
				}

				return result
			}

			if (this.data.reason) {
				return this.data.reason
					.split(',')
					.map(reason => getDescription(reason, this.data.rating))
					.join(', ')
			}
		},
	},
	components: {
		Lang,
	},
}
</script>

<style lang="stylus" scoped>
// @import '../../../common/fonts/brother-1816-mixins.styl'
.InsightsDetailsCell {
	max-width: 350px
}

.reason {
	h1 {
		font-size: 14px
		color: #888
		margin: 0
		padding: 0
		// brother-bold()
	}

    p {
        margin: 0
        padding: 0
        // TODO
        // font-weight: 700
    }
}

.message {
	font-style: italic
	font-weight: 300
}
</style>
