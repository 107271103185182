<!--
	Usage: <TopBar/>
-->

<template>
	<div class="TopBar" :class="{ showMainNavigation, showAudioFailure }">
		<img class="brandIcon" src="/common/img/resq_logo.png">

		<!-- <div class="pageTitle">{{page}}</div> -->
		<a href="#offers" class="backButton">
			<ArrowBack/>
		</a>

		<div class="TopBar-tabs">
			<TopBarTab v-for="(tab, index) of tabs" :key="`TopBar-tabs-${index}`" :data="tab"/>
		</div>

		<div class="rightPart rightPart-wideScreen">
			<div class="dropdown toggleProfileDropDown" @click.stop="toggleDropDown">
				<div class="text">
					{{name}}
					<MenuDown/>
				</div>
			</div>
		</div>
		<div class="rightPart rightPart-narrowScreen">
			<div class="dropdown burgerMenuIcon toggleProfileDropDown" @click.stop="toggleDropDown">
				<CloseIcon v-if="burgerMenuVisible"/>
				<Menu v-else/>
			</div>
		</div>
	</div>
</template>

<script>
import TopBarTab from './TopBarTab.vue'
import { toggleDropDown } from './TopBarContextMenu.vue'
import MenuDown from 'vue-material-design-icons/MenuDown.vue'
import Menu from 'vue-material-design-icons/Menu.vue'
import ArrowBack from 'vue-material-design-icons/ArrowLeft.vue'
import CloseIcon from 'vue-material-design-icons/Close.vue'
import Vue from 'vue'
import vueStore from '../es6/store'

export default {
	name: 'TopBar',
	props: {},
	data: () => {
    const languageCode = window.getLanguage();
    const newOrdersViewUrl = `${use.env.NEW_PARTNER_APP_URL_BASE}/${languageCode}/orders`
    const ordersHref = !!use.profile.useNewWebApp ? newOrdersViewUrl : '#orders'

    let tabs = [
			!use.profile.isModel ? {
				label: lang.p.tab.allOffers,
				href: '#offers',
				selected: true,
			} : null,
			{
				label: lang.p.tab.templates,
				href: '#templates',
			},
			!use.profile.isModel ? {
				label: lang.p.tab.orders,
				href: ordersHref,
				numberBadgeKey: 'unfetchedOrdersCount',
				extraHtml:
					`<span class="audioOffElement" title="Audio muted until the page is clicked">🔇</span>`
			} : null,
		].filter(Boolean)

		return {
			tabs,
			profile: null,
			burgerMenuVisible: false,

			namePostFix: '',
		}
	},
	created() {
		wait.profile.then(profile => {
			this.profile = profile

			wait.env.then(env => {
				if (env.admin) {
					let modelString = this.profile.isModel ? 'model ' : ''
					this.namePostFix = ` (${modelString}${profile.id}) \n ADMIN `
				}
			})
		})

		this.updateBodyPaddingTop()
	},
	computed: {
		showMainNavigation() {
			return !!this.tabs.find(tab => tab.href === '#' + vueStore.state.currentPage)
		},
		page() {
			return vueStore.state.currentRoute && vueStore.state.currentRoute.title
		},
		name() {
			let profile = this.profile
			let name = ''
			if (profile) {
				if (profile.isModel && profile.enterprise) {
					name = profile.enterprise.name
				} else {
					name = profile.name
				}
			}
			return name + this.namePostFix
		},
		showAudioFailure() {
			// In computed we must call getter of both values
			let able = vueStore.state.ableToPlayAudio
			let fail = vueStore.state.audioPlayingFailed
			return !able && fail
		},
	},
	watch: {
		showMainNavigation(show) {
			this.updateBodyPaddingTop()
		},
	},
	methods: {
		toggleDropDown,
		updateBodyPaddingTop() {
			if (this.showMainNavigation) {
				document.body.style.paddingTop = '120px'
			} else {
				document.body.style.paddingTop = '60px'
			}
		}
	},
	components: {
		TopBarTab,
		MenuDown,
		Menu,
		ArrowBack,
		CloseIcon
	},
}

let topBar = null

export function createVueTopBar() {
	let wrapper = new Vue({
		render: h => h(window.TopBar),
	}).$mount('.topBarPlaceholder')

	topBar = wrapper.$children[0]
}

</script>

<style lang="stylus" scoped>
@import "../css/variables.styl"

.TopBar {
	background: #1BCBAC
	position: fixed
	width: 100%
	top: 0
	height: $small_topbar_height
	text-align: center
	user-select: none
	font-size: 14px
	font-weight: 600
	color: white
	z-index: 99
	overflow: hidden
}

.brandIcon {
	position: absolute
	top: 20px
	height: 20px
	left: 30px
}

>>> .audioOffElement {
	display none
}
.showAudioFailure >>> .audioOffElement {
	display: inline;
	position: absolute;
	top: 14px;
	height: 20px;
	left: 5px;
	font-size: 18px;
}

.pageTitle {
	// hidden in wide view
	display: none
}

.TopBar-tabs {
	margin: 0 auto
	height: $small_topbar_height
	display: inline-block
}

.rightPart {
	height: 100%
	display: inline-block
	position: absolute
	top: 0
	right: 0
	margin-right: 10px
}

.rightPart-narrowScreen {
	display: none
}

.rightPart > * {
	height: $small_topbar_height
	overflow: hidden
	display: inline-block
	vertical-align: bottom
}

.dropdown {
	height: $small_topbar_height
	right: 0
	cursor: pointer

	.text {
		max-width: 130px
		display: inline-block
		position: relative
		top: 51%
		transform: translateY(-50%)
		margin-right: 20px

		.material-design-icon {
			font-size: 24px
			position: absolute
			right: -27px
			top: calc(50% - 5px)
			transform: translateY(-50%)
		}
	}

	margin-right: 20px

	&:last-child {
		margin-right: 0
	}
}

.backButton {
	display: none
}

.dropdown.toggleProfileDropDown {
	display: inline-block
	padding: 0 20px
	padding-left: 10px
	position: relative
}

.burgerMenuIcon > .material-design-icon {
	position: relative
	top: 15px
	font-size: 24px
}

@media screen and (max-width: $max_mobile_top_bar_width) {
	.dropdown .text {
		display: none
	}

	.brandIcon {
		left: 50%
		transform: translateX(-50%)
	}

	// .TopBar:not(.showMainNavigation) .brandIcon {
	// display none
	// }
	.TopBar:not(.showMainNavigation) .pageTitle {
		display: inline-block
		font-size: 18px
		position: relative
		top: 50%
		transform: translateY(-50%)
	}

	.TopBar.showMainNavigation {
		height: $big_topbar_height
	}

	.TopBar-tabs {
		margin-top: $small_topbar_height
		width: 100%
		text-align: center
		// Flexbox makes it more beautiful but this works also without flex
		display: flex
	}

	.rightPart-narrowScreen {
		display: inline-block
		height: $small_topbar_height
		margin-right: 15px
	}

	.rightPart-wideScreen {
		display: none
	}

	.TopBar:not(.showMainNavigation) {
		.TopBar-tabs {
			display: none
		}

		.backButton {
			display: inline-block
			position: absolute
			padding: 15px 20px
			left: 0
			top: 0
			height: 100%
			color: #fff
			font-size: 24px
		}
	}
}

</style>
