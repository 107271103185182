//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InsightsBestOfResqMeter from '../InsightsBestOfResqMeter.vue'
import InsightsRatingBox from '../InsightsRatingBox.vue'
import { Table } from '@resqclub/resq-components'
import Vue from 'vue'
import InsightsDateCell from '../insights/InsightsDateCell.vue'
import InsightsTableRatingCell from '../insights/InsightsTableRatingCell.vue'
import InsightsDetailsCell from '../insights/InsightsDetailsCell.vue'
import InsightsPublishCell from '../insights/InsightsPublishCell.vue'
import vueStore from '../../es6/store'

import { setProviderData } from '../../es6/util'

const MAX_REVIEWS = 250

export default {
	name: 'InsightsView',
	props: [],

	data: () => ({
		lang: window.lang,
		percentages: {
			good: 0,
			okay: 0,
			bad: 0,
		},
		renderedColumns: [
			{
				id: 'date',
				component: InsightsDateCell
			},
			{
				id: 'offer',
				class: 'Insights-offerCell',
			},
			{
				id: 'rating',
				// class: 'Insights-offerCell',
				component: InsightsTableRatingCell
			},
			{
				id: 'details',
				component: InsightsDetailsCell
				// class: 'Insights-offerCell',
			},
			{
				id: 'publish',
				component: InsightsPublishCell
			},
		]
	}),
	computed: {
		reviewDetailsExist() {
			return this.reviews.some(r => r.text)
		},
		reviews() {
			return vueStore.state.reviews || []
		},
		formattedReviews: function() {
			let result = this.reviews.map(review => {
				let offerItems = review.order.orderItems.filter(oi => oi.type === 'offer')
				const SHOW_QUANTITY_WHEN_ONE = offerItems.length > 1
				let offerText = offerItems
					.map(oi => {
						if (!oi.offer) {
							return '...'
						}

						let price = window.formatCurrency(oi.unitPrice, oi.currency, true)

						if (oi.quantity > 1 || SHOW_QUANTITY_WHEN_ONE) {
							return `${oi.quantity} x ${oi.offer.name} (${price})`
						} else {
							return `${oi.offer.name} (${price})`
						}
					})
					.join('\n')
				return {
					date: {
						dateString: dateFormat(review.createdAt, lang.format.dateShort),
						isPublished: review.isPublished,
					},
					offer: offerText,
					rating: review.rating,
					details: {
						// reason text depends on this
						rating: review.rating,
						reason: review.reason,
						message: review.text,
					},
					publish: {
						reviewId: review.id,
						isPublishable: review.isPublishable,
						isPublished: review.isPublished,
						text: review.text,
					},
				}
			})

			result = result.slice(0, MAX_REVIEWS)
			return result
		},
	},
	mounted() {
		vueStore.dispatch('updateReviews')
	},
	watch: {
		reviews(reviews) {
			if (reviews.length === 0) {
				return
			}
			let latestId = Math.max(...reviews.map(r => r.id))

			// Don't let admin remove the review bubble
			if (!use.env.admin) {
				const DAY = 1000 * 60 * 60 * 24
				let bubbleExpiresAt = new Date(Date.now() + DAY *
					use.env.PROVIDER_NEW_REVIEW_MAX_AGE_DAYS)
				setProviderData('latestSeenReviewId', latestId, bubbleExpiresAt)
			}
			vueStore.commit('setNewReviewCount', 0)

			let reviewCounts = {
				good: 0,
				okay: 0,
				bad: 0,
				problem: 0,
				resolvedProblem: 0,
			}

			let scoreSum = 0

			for (const review of reviews) {
				reviewCounts[review.rating]++
				scoreSum += review.order.ratingScore
			}

			if (reviews.length) {
				this.percentages.good = Math.round(
					(reviewCounts.good / reviews.length) * 100
				)
				this.percentages.okay = Math.round(
					(reviewCounts.okay / reviews.length) * 100
				)
				this.percentages.bad = Math.round(
					((reviewCounts.bad +
						reviewCounts.problem +
						reviewCounts.resolvedProblem) /
						reviews.length) *
					100
				)
			}
		}
	},
	components: {
		InsightsBestOfResqMeter,
		InsightsRatingBox,
		Table,
	},
}
