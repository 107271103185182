//
//
//
//
//
//

import ContextMenu from './ContextMenu.vue'
import Vue from 'vue'
import InsightsContextMenuItemDecoration from './insights/InsightsContextMenuItemDecoration.vue'

const TopBarContextMenu = {
	name: 'TopBarContextMenu',
	// Props are just copy pasted from ContextMenu
	props: {
		links: {
			type: Array, // [{ label: 'Click me', callback: () => doSomething() }]
			default: () => [],
		},
		css: String,
		onClose: Function,
	},
	data: () => ({}),
	mounted() {
		let languageControllerJQueryElement = createLanguageController()
		languageControllerJQueryElement.appendTo(this.$refs.languageControllerContainer)
	},
	computed: {},
	methods: {},
	components: {
		ContextMenu,
	},
}
export default TopBarContextMenu

let dropDown = null

export function toggleDropDown() {
	if (document.querySelector('.TopBarContextMenu')) {
		// it already exists.
		dropDown.$destroy()
		return
	}

	dropDown = new Vue({
		render: h =>
			h(TopBarContextMenu, {
				props: {
					links: [
						{
							label: lang.p.tab.feedback,
							href: '#insights',
							showWhen: () => !use.profile.isModel,
							decorationComponent: InsightsContextMenuItemDecoration
						},
						{
							label: lang.p.tab.stats,
							href: '#stats',
							showWhen: () => !use.profile.isModel,
						},
						{
							isSeparator: true,
							showWhen: () => !use.profile.isModel,
						},
						{
							label: lang.p.tab.myInfo,
							href: '#profile',
							showWhen: () => !use.profile.isModel,
						},
						{
							label: lang.p.tab.settings,
							href: '#settings',
							showWhen: () => !use.profile.isModel,
						},
						{
							label: lang.p.tab.support,
							callback: () => {
								if (window.Intercom) {
									window.Intercom('show')
								}
							},
							showWhen: (state, getters) => {
								return !use.profile.isModel && !getters.shouldShowIntercomWidget
							},
						},
						{
							isSeparator: true,
							showWhen: () => !use.profile.isModel,
						},
						{
							label: lang.common.helpCenterName,
							href: lang.common.helpCenterLink,
							targetBlank: true,
							showWhen: () => true,
						},
            {
              label: lang.usermenu.menuitem.marketingMaterial,
              href: lang.usermenu.url.marketingMaterial,
              targetBlank: true,
              showWhen: () => true,
            },
						{
							label: lang.p.button.logout,
							callback: () => logout(),
							showWhen: () => true,
						},
					],
					onClose: () => {
						this.burgerMenuVisible = false
					},
				},
			}),
	}).$mount()

	this.burgerMenuVisible = true

	// without timeout, context menu would close because of a click
	setTimeout(() => {
		document.querySelector('body').appendChild(dropDown.$el)
	}, 1)
}
