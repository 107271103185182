// A module to see if user is about to do something.
// When you want to refresh the page, only do it when

let lastActivity = Date.now()

function updateActivity() {
    lastActivity = Date.now()
}

export function start() {
    window.addEventListener('keydown', updateActivity)
    window.addEventListener('keyup', updateActivity)
    window.addEventListener('keypress', updateActivity)

    window.addEventListener('mousedown', updateActivity)
    window.addEventListener('mouseup', updateActivity)
    window.addEventListener('mousemove', updateActivity)

    window.addEventListener('scroll', updateActivity)
    window.addEventListener('resize', updateActivity)

    window.addEventListener('touchstart', updateActivity)
    window.addEventListener('touchend', updateActivity)
    window.addEventListener('touchcancel', updateActivity)
    window.addEventListener('touchmove', updateActivity)
}

const IDLE_SECONDS = 30

export function isIdle() {
    return Date.now() - lastActivity > IDLE_SECONDS * 1000
}

// Return true if there is popup, modal or something like that open that may contain unsaved data.
// Also detects pages like My Info, where there are inputs and a "Save changes" button.
export function isSomethingImportantGoingOn() {
    const popupSelectors = [
        '.ContextMenu',
        '.CreateOfferFromTemplate',
        '.intercom-messenger-frame',
        '.modalDialog'
    ]

    for (const popupClass of popupSelectors) {
        if (document.querySelector(popupClass)) {
            return true
        }
    }

    if (
        document.activeElement &&
		['input', 'textarea', 'select'].includes(document.activeElement.nodeName.toLowerCase())
    ) {
        return true
    }

    return false
}

// Is it ok to reload the page?
export function isItSafeToRefresh() {
    return isIdle() && !isSomethingImportantGoingOn()
}

// setInterval(function() {
// 	log(
// 		`Last activity ${((Date.now() - lastActivity) / 1000).toFixed(1)} seconds ago, idle?`,
// 		isIdle(),
// 		'menu?',
// 		isSomethingImportantGoingOn()
// 	)
// }, 1000)
