//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Lang from './Lang.vue'

export default {
	name: 'InsightsBestOfResqMeter',
	data: () => ({
		lang: window.lang,
		isBestOf: false, // will be overridden when we get profile info
		progressPercentage: 0,
	}),
	computed: {},
	mounted() {
		// profile might not be there when this component is created
		window.wait.profile.then(profile => {
			this.isBestOf = profile.isBestOf

			// This same formula is in server side.
			let providerScore = profile.ratingScore

			this.progressPercentage = Math.round(
				(100 * providerScore) / use.env.PROVIDER_SCORE_THRESHOLD_FOR_BEST_OF
			)

			if (this.progressPercentage < 0) {
				this.progressPercentage = 0
			} else if (this.progressPercentage > 100) {
				this.progressPercentage = 100
			}
		})
	},
	methods: {},
	components: { Lang },
}
