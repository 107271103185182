<template>
	<div class="InsightsRatingBox">
		<div class="faceArea">
			<img
				v-if="rating == 'good'"
				src="/common/img/review-emoji_good.svg"
				alt="happy face"
			/>
			<img
				v-else-if="rating == 'okay'"
				src="/common/img/review-emoji_okay.svg"
				alt="okay face"
			/>
			<img
				v-else-if="rating == 'bad'"
				src="/common/img/review-emoji_bad.svg"
				alt="sad face"
			/>

			<!-- <div class="face">
                :)
            </div> -->
		</div>
		{{ percentage }}% {{ lang.review.rating[rating] }}
	</div>
</template>

<script>
export default {
	name: 'InsightsRatingBox',
	props: ['rating', 'percentage'],
	data: () => ({
		lang: window.lang,
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>

<style lang="stylus" scoped>
.InsightsRatingBox {
	background: #ececec
	color: black
	margin: 0 10px
	position: relative
	border-radius: 5px
	height: 70px
	width: 15vw
	max-width: 150px
	padding-top: 38px
	text-align: center
	display: inline-block
	font-size: 15px
	white-space: nowrap
}

.faceArea {
	$size = 60px
	width: $size
	height: $size
	background: white
	border-radius: ($size / 2)
	position: absolute
	left: 50%
	top: 0%
	transform: translateX(-50%) translateY(-50%)

	img {
		position: absolute
		width: 30px
		top: 50%
		left: 50%
		transform: translateX(-50%) translateY(-50%)
	}
}

@media screen and (max-width: 1100px) {
	.InsightsRatingBox {
		background: transparent

		width: 20vw
	}

	.faceArea {
		background: transparent
		top: 10px
	}
}
</style>
