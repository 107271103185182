import { el, text, list } from 'redom'

/*

Are you wondering where the actual tag parsing logic is?

I was too!

It's hidden within a call to 'parseTags()', which is a function defined in
common/js/tagParser/tagParser.js.

You're welcome!

*/

export default class TagProposer {
    constructor(addTagCallback, tagIdSelectedChecker, allSelectedByDefault, source) {
        this.source = source || 'default'
        this.addTagCallback = addTagCallback
        this.tagIdSelectedChecker = tagIdSelectedChecker
        this.allSelectedByDefault = allSelectedByDefault
        this.tagWasOriginallySelected = {} // tagId -> true|false
        this.userSetTagStatuses = {}

        if (allSelectedByDefault) {
            this.el = el('div.tagProposer',
                el('div.tagProposer-header',
                    lang.p.tagProposer.suggestedTagsTitle
                ),
                this.list = list('div.tagProposerList', TagProposerButton, tagId => tagId, this),
                el('div.tagProposer-footer',
                    lang.p.tagProposer.autoAddDescription
                )
            )
        } else {
            this.el = el('div.tagProposer',
                el('div.tagProposer-header',
                    lang.p.tagProposer.title
                ),
                this.list = list('div.tagProposerList', TagProposerButton, tagId => tagId, this),
                this.addAllButton = window.createTextButton(lang.p.tagProposer.selectAllButton, () => {
                    this.list.views.forEach(tagProposerButton => {
                        tagProposerButton.add()
                    })
                }).addClass('tagProposerAddAll')
            )
        }

        this.timeout = null
        this.updateText = null
    }

    updateWithDelay(text) {
        this.updateText = text

        if (this.timeout)
            return

        this.timeout = setTimeout(() => {
            this.timeout = null
            this.update(this.updateText)
        }, 100)
    }
    update(text) {
        let tags = window.parseTags(text, use.profile.country.languages)

        tags = window.cleanTagConflicts(tags)

        tags = tags.filter(tagId => window.tags[tagId])

        tags.forEach(tagId => {
            if (this.tagWasOriginallySelected[tagId] === undefined) {
                this.tagWasOriginallySelected[tagId] = !!this.tagIdSelectedChecker(tagId)
            }
        })

        tags = tags.filter(tagId => !this.tagWasOriginallySelected[tagId])

        this.list.update(tags)

        if (tags.length) {
            this.el.classList.add('visible')
        } else {
            this.el.classList.remove('visible')
        }
    }

    tagsChangedElsewhere() {
        this.list.views.forEach(tagProposerButton => {
            tagProposerButton.update()
        })
    }
}

TagProposer.addToDialog = function (dialog, inputBlock, allSelectedByDefault) {
    var tagProposer = new TagProposer(
        TagProposer.createAddCallbackForDialog(dialog),
        TagProposer.createTagIdSelectedChecker(dialog),
        allSelectedByDefault
    )
    inputBlock.find('.inputBlockContent').append(tagProposer.el)
    var input = inputBlock.find('input,textarea')
    function updateWithDelay() {
        tagProposer.updateWithDelay(input.val())
    }
    input.keyup(updateWithDelay)
    setTimeout(updateWithDelay, 100)

    dialog.getDialogElement().on('tagButtonStateChanged', () => {
        tagProposer.tagsChangedElsewhere()
    })
}

TagProposer.createAddCallbackForDialog = function (dialog) {
    return function (tagId, isAdded) {
        const tagButton = dialog.getDialogElement().find('#tag-button-' + tagId)
        tagButton.toggleClass('selected', isAdded)
    }
}

TagProposer.createTagIdSelectedChecker = function (dialog) {
    return function (tagId) {
        const tagButton = dialog.getDialogElement().find('#tag-button-' + tagId)
        return tagButton.hasClass('selected')
    }
}

class TagProposerButton {
    constructor(tagProposer) {
        this.tagProposer = tagProposer
        this.tagId = null
        this.el = el('div.tagProposerButton',
            this.image = el('img.tagProposerButtonImage'),
            this.text = el('span.tagProposerButtonText'),
            {
                onclick: () => {
                    const isSelected = this.el.classList.contains('selected')
                    if (isSelected) {
                        this.remove()
                    } else {
                        this.add()
                    }
                }
            }
        )
    }
    update(tagId) {
        this.tagId = tagId || this.tagId
        this.text.textContent = window.tags[this.tagId].name
        this.image.src = window.tags[this.tagId].iconUrl

        const tagWasSelected = !!this.tagProposer.tagIdSelectedChecker(this.tagId)

        if (tagWasSelected) {
            this.add(true)
        } else {
            this.remove(true)
        }
    }
    add(skipCallback) {
        if (!this.el.classList.contains('selected')) {
            this.el.classList.add('selected')
            if (!skipCallback) {
                this.tagProposer.addTagCallback(this.tagId, true)
                this.tagProposer.userSetTagStatuses[this.tagId] = true
            }
        }
    }
    remove(skipCallback) {
        if (this.el.classList.contains('selected')) {
            this.el.classList.remove('selected')
            if (!skipCallback) {
                this.tagProposer.addTagCallback(this.tagId, false)
                this.tagProposer.userSetTagStatuses[this.tagId] = false
            }
        }
    }

    // onmount is called after update
    onmount() {
        if (this.tagProposer.allSelectedByDefault && this.tagProposer.userSetTagStatuses[this.tagId] !== false) {
            this.add()
        }
    }
    onunmount() {
    }
}
