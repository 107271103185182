import vueStore from './store'
import log from './log'

window.intercomSettings = null

function updateIntercom() {

    if (!window.intercomSettings) {
        return
    }

    // Official Intercom SDK
    // As we found out, it can also be called to change settings

    ;(function() {
        var w = window
        var ic = w.Intercom
        if (typeof ic === 'function') {
            ic('reattach_activator')
            ic('update', window.intercomSettings)
        } else {
            var d = document
            var i = function() {
                i.c(arguments)
            }
            i.q = []
            i.c = function(args) {
                i.q.push(args)
            }
            w.Intercom = i

            // Chrome 36 bugfix:
            // In strict mode code, functions can only be declared at top level or
            // immediately within...
            //function l() {
            var l = function() {
                var s = d.createElement('script')
                s.type = 'text/javascript'
                s.async = true
                s.src = 'https://widget.intercom.io/widget/zk0wcx4a'
                var x = d.getElementsByTagName('script')[0]
                x.parentNode.insertBefore(s, x)
            }

            if (d.readyState === 'complete') { // this check added by ResQ
                // log('CASE 1 Intercom readyState complete, init')
                l()
            } else if (w.attachEvent) {
                // log('CASE 2 Intercom attachEvent to onload')
                w.attachEvent('onload', l)
            } else {
                // log('CASE 3 Intercom addEventListener onload')
                w.addEventListener('load', l, false)
            }
        }
    })()
}

vueStore.watch((state, getters) => {

    // Need to call the getter before returning so that vuex properly
    // detectes the dependency
    const shouldShow = getters.shouldShowIntercomWidget

    if (!window.intercomSettings) {
        return
    }

    window.intercomSettings.hide_default_launcher = !shouldShow

    // shouldShow depends on window size, so this watcher will be called
    // all the time when the window is resized. It doesn't seem to do
    // much harm, though.
    updateIntercom()
})

export function intercomInit(myInfo) {
    if (!use.env.ENABLE_INTERCOM_PROVIDER_CHAT /* || use.env.admin */) {
        console.log('intercomInit Intercom chat disabled, returning')
        return
    }

    if (!use.env.INTERCOM_APP_ID) {
        console.warn('intercomInit Intercom provider chat enabled but INTERCOM_APP_ID is not specified, returning')
        return
    }

    window.intercomSettings = {
        app_id: use.env.INTERCOM_APP_ID,
        user_id: myInfo.intercom.externalId,
        user_hash: myInfo.intercom.hmacDigest,
        name: myInfo.name,
        email: myInfo.email,
        created_at: new Date(myInfo.registeredDate).getTime() / 1000 | 0,
        provider_city: myInfo.locationCity,
        provider_latitude: myInfo.latitude,
        provider_longitude: myInfo.longitude,
        provider_country: myInfo.country.id,
        user_type: 'provider',
        hide_default_launcher: !vueStore.getters.shouldShowIntercomWidget,
    }

    updateIntercom()
}


