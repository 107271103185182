import { TimeValidator } from './timeValidator'
export class TimeValidatorFactory {
    constructor(options) {
        this._min = options.min
        this._max = options.max
    }

    create(validatorOptions) {

        const options = {
            min: this._min,
            max: this._max
        }

        $.extend(options, validatorOptions)

        const validator = new TimeValidator(options)

        return validator
    }
}
