//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Plus from 'vue-material-design-icons/Plus.vue'
import SearchWeb from 'vue-material-design-icons/Magnify.vue'
import CloseIcon from 'vue-material-design-icons/Close.vue'
import ArrowBack from 'vue-material-design-icons/ArrowLeft.vue'
import ArrowRight from 'vue-material-design-icons/ChevronRight.vue'
import vueStore from '../es6/store.js'
import Vue from 'vue'
import { dismissableMixin } from '@resqclub/resq-components/mixins'

const CreateOfferFromTemplate = {
	mixins: [dismissableMixin],
	name: 'CreateOfferFromTemplate',
	props: [],
	data: () => ({
		searchString: '',
		lang: window.lang,
		profile: use.profile,
	}),
	computed: {
		offerTemplates() {
			let searchString = this.searchString.toLowerCase()

			// Can be null if offerTemplates haven't been fetched yet
			let offerTemplates = vueStore.state.offerTemplates

			if (offerTemplates) {
				// Copy before sorting
				offerTemplates = [...offerTemplates]

				// Sort order:
				// - primary, timesOfferedLately descending
				// - secondary, name ascending
				offerTemplates.sort((ot1, ot2) => {
					return ot2.timesOfferedLately - ot1.timesOfferedLately || ot1.name - ot2.name
				})

				return offerTemplates
					.filter(
						t =>
							t.name.toLowerCase().includes(searchString) ||
							t.description.toLowerCase().includes(searchString)
					)
					.map(offerTemplate => {
						let isNonsurplus = window.isOfferOrOfferTemplateNonsurplus(offerTemplate)
						let result = { ...offerTemplate, isNonsurplus }
						return result
					})
			} else {
				return []
			}
		},
	},
	mounted() {
		document.body.classList.add('scroll-freeze')
	},
	destroyed() {
		document.body.classList.remove('scroll-freeze')
		this.$emit('destroyed')
	},
	methods: {
		createPriceText(price) {
			return formatCurrency(price, use.profile.currency, true)
		},
		templateClicked(template) {
			this.$destroy()

			window.openCreateOfferDialog(template)
		},
		createNewOfferClicked() {
			this.$destroy()

			window.openCreateOfferFromScratchDialog()
		},
		backButtonClicked(event) {
			this.$destroy()
		},
	},
	components: {
		Plus,
		SearchWeb,
		CloseIcon,
		ArrowBack,
		ArrowRight,
	},
}
export default CreateOfferFromTemplate

export function openCreateOfferFromTemplatePopup(closeCallback) {
	let popup = new Vue({
		render: h =>
			h(CreateOfferFromTemplate, {
				on: {
					destroyed() {
						closeCallback && closeCallback()
					},
				},
			}),
	}).$mount()

	// without timeout, context menu would close because of a click
	setTimeout(() => {
		document.querySelector('body').appendChild(popup.$el)
		let createOfferFromTemplateInput = document.querySelector('.CreateOfferFromTemplate input')
		createOfferFromTemplateInput && createOfferFromTemplateInput.focus()
	}, 1)
}
