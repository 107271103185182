import { TimeInputRange } from './timeInputRange.js'
import { TimeValidatorFactory } from './timeValidatorFactory.js'
// import { TimeValidator } from './timeValidator';

export class OfferTimesInputs {
    constructor(options) {
        this.el = $('<div>')
        this.el.addClass('component providerRangeTable')

        options.validateNow = !!options.validateNow
        this.init(options)
    }

    init(options) {
        var self = this

        this.orderRange = new TimeInputRange({
            inputChanged: function(input, userInteraction) {
                return self.inputChanged(input, userInteraction)
            },
            label: lang.p.dialog.orderpickuptimes.order,
            name: 'order'
        })

        this.fetchRange = new TimeInputRange({
            inputChanged: function(input, userInteraction) {
                return self.inputChanged(input, userInteraction)
            },
            label: lang.p.dialog.orderpickuptimes.pickup,
            name: 'fetch'
        })

        this.timeWarningArea = $('<div>')
        this.timeWarningArea.addClass('warningStyle')

        this.options = {
            afterNow: false,
            daysFromNow: 0,
            validateNow: true,
        }

        $.extend(this.options, options)
        this.callbacks = {}

        this._validatorFactory = new TimeValidatorFactory({
            min: '05:00:00',
            max: '04:00:00'
        })

        this.times = OfferTimesInputs.removeSeconds(this.options.times)

        this.validators = []

        for (var i in this.inputs) {
            var input = this.inputs[i]
            input.validate = this.times[input.name]
        }

        this.el.html('')
        this.el.append(this.timeWarningArea)
        var toolbarEl = $('<div>').addClass('toolbar-el')
        this.el.append(toolbarEl)
        this.el.append(this.orderRange.el)
        this.el.append(this.fetchRange.el)

        this.setInputValues()

        if (this.options.validateNow) {
            this.validateTimesOnServer()
        }
    }

    validate(validatorOptions) { //shorthand
        var validator = this._validatorFactory.create(validatorOptions)
        return validator
    }

    on(name, callback) {
        if (name && callback && typeof callback === 'function') {
            this.callbacks[name] = callback
        }
    }

    setInputValues(options) {
        this.orderRange.setRange(this.times.orderStart, this.times.orderEnd)
        this.fetchRange.setRange(this.times.fetchStart, this.times.fetchEnd)
    }

    inputChanged(input, userInteraction) {
        var defaultValue = this.times[input.name]

        var validatorOpts = {}
        if (this.options.nowTime) {
            validatorOpts.nowTime = this.options.nowTime
        }

        var currentValidator = this.validate(validatorOpts).setVal(input.val || defaultValue)

        let val = currentValidator.val

        if (!val || val == '') {
            console.error('OfferTimesInput, inputChanged(): invalid value provided', val, currentValidator, input.val,
                defaultValue)
            return defaultValue
        }
        currentValidator.addValidator('validateAbsoluteBounds')

        var nowValue = new window.OfferTime().fromDate(new Date()).getValue()

        if (validatorOpts && validatorOpts.nowTime && validatorOpts.nowTime.getValue()) {
            nowValue = validatorOpts.nowTime.getValue()
        }

        val = currentValidator.val
        //TODO: orderStart after fetchStart

        if (input.name === 'orderStart' && this.validate(validatorOpts).setVal(
            this.times['fetchStart']).offerTime.getValue() < currentValidator.offerTime.getValue()) {
            this.times['fetchStart'] = currentValidator.val
            this.input('fetchStart').val = currentValidator.val
        } else if (input.name === 'fetchStart' && this.validate(validatorOpts).setVal(
            this.times['orderStart']).offerTime.getValue() > currentValidator.offerTime.getValue()) {
            this.input('orderStart').val = currentValidator.val
            this.times['orderStart'] = currentValidator.val
        } else if (input.name === 'orderEnd' && this.validate(validatorOpts).setVal(
            this.times['fetchEnd']).offerTime.getValue() < currentValidator.offerTime.getValue()) {
            this.times['fetchEnd'] = currentValidator.val
            this.input('fetchEnd').val = currentValidator.val
        } else if (input.name === 'fetchEnd' && this.validate(validatorOpts).setVal(
            this.times['orderEnd']).offerTime.getValue() > currentValidator.offerTime.getValue()) {
            this.times['orderEnd'] = currentValidator.val
            this.input('orderEnd').val = currentValidator.val
        } else {
            // log('!!! case NOTHING')
        }

        //TODO: offertime too short

        // log(`!!! setting this.times['${input.name}'] to ${currentValidator.val}`)

        this.times[input.name] = currentValidator.val

        var inputValues = {}
        inputValues[input.name] = val
        this.setInputValues(inputValues)
        if (this.callbacks.valuesChanged && typeof this.callbacks.valuesChanged === 'function') {
            this.callbacks.valuesChanged.call(this, this.values)
        }

        if (userInteraction) {
            this.validateTimesOnServer()
        }

        return val
    }

    validateTimesOnServer() {
        const daysFromNowOriginal = this.options.daysFromNow
        const offerTimes = _.transform(this.times, function(result, value, key) {
            let daysFromNow = daysFromNowOriginal
            const offerDate = window.stringToDate(value)
            const offerHour = offerDate.getHours()
            if (offerHour < 5) {
                daysFromNow++
            }
            result[key] = new Date(offerDate.setDate(offerDate.getDate() + daysFromNow)).toISOString()
        }, {})

        const url = this.options.offerId
            ? `provider/offerValidate/${this.options.offerId}`
            : 'provider/offerValidate'
        window.getRequest(url, offerTimes).done(result => {
            const validationResults = Object.values(JSON.parse(result))
            this.timeWarningArea.empty()
            validationResults.forEach(res => {
                if (res.message) {
                    const warningEl = $('<p class="validationMessage">').text(res.message)
                    if (!res.valid) {
                        warningEl.addClass('validationError')
                    } else if (res.warning) {
                        warningEl.addClass('validationWarning')
                    }
                    this.timeWarningArea.append(warningEl)
                }
            })
        })
    }

    get inputs() {
        var a = []
        a.push(...this.orderRange.inputs)
        a.push(...this.fetchRange.inputs)
        return a
    }

    get values() {
        return this.times
    }

    input(inputName) {
        var names = ['orderStart', 'orderEnd', 'fetchStart', 'fetchEnd']
        var pos = names.indexOf(inputName)
        if (pos >= 0) {
            return this.inputs[pos]
        }
    }

    get now() {
        return this._now
    }

    /**
	 Loop Through object values and ensure each item has seconds (00:00:00)
	 */
    static addSeconds(values) {
        var ret = {}
        for (var key in values) {
            var value = values[key]
            if (value.split('').length == 2) {
                value = value + ':00'
            }

            ret[key] = value
        }
        return ret
    }

    static removeSeconds(values) {

        var ret = {}
        for (var key in values) {
            var value
            var parts = values[key].split(':')
            if (parts.length == 3) {
                value = parts[0] + ':' + parts[1]
            }

            ret[key] = value
        }
        return ret
    }

    getTimes() {
        const ret = {}

        for (var key in this.times) {
            ret[key + 'Time'] = this.times[key] + ':00'
        }
        //console.log("returing getTimes()", this.times, ret)

        return ret
    }

    getDates() {

    }

    getEl() {
        return this.el
    }
}
