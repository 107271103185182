<template>
	<div v-if='newReviewCount > 0' class="InsightsContextMenuItemDecoration">
		<div class="text">
			{{newReviewCount > 99 ? '99+' : newReviewCount}}
		</div>
	</div>
</template>

<script>

import vueStore from '../../es6/store'

export default {
	name: "InsightsContextMenuItemDecoration",
	computed: {
		newReviewCount() {
			return vueStore.state.newReviewCount
		},
	}
}
</script>

<style scoped lang="stylus">

.InsightsContextMenuItemDecoration {
	display: inline-block;
	color: #fff;
	text-align: center;
	background: #1bcbac;
	min-width: 1.2rem;
	height: h = 1.2rem;
	border-radius: 0.5 * h;
	vertical-align: middle;
	margin-left: 5px;
	position: relative;
	top: -2px;

	padding: 0 6px;

	// max-width fixes 99+ align without any additional class tricks
	max-width: 2.0rem;

	.text {
		font-size: 13px;
		font-weight: bold;
		position: relative;
		top: 1px;
	}

	@media screen and (max-width: 850px) {
		background white
		color: #1bcbac
	}
}

</style>
