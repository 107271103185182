//
//
//
//
//
//
//
//
//

export default {
	name: 'InsightsTableRatingCell',
	props: ['data'],
	data: () => ({
		lang: window.lang,
	}),
	computed: {},
	methods: {},
	components: {},
}
