<template>
	<div class="CreateOfferFromTemplate">
		<div class="mobileTopBar">
			<a class="backButton" href="#offers" @click.prevent="backButtonClicked">
				<ArrowBack />
			</a>
			<img class="brandIcon" src="/common/img/resq_logo.png" />
		</div>
		<div class="searchArea">
			<SearchWeb />
			<input
				class="CreateOfferFromTemplate-input"
				:placeholder="lang.offers.search.hintText"
				type="text"
				v-model="searchString"
			/>
		</div>

		<div
			class="createNewOfferButton buttonLike"
			@click="createNewOfferClicked"
			v-if="!profile.enforceSharedTemplates"
		>
			<span>
				<Plus />
				{{ lang.offers.create.fromScratch }}
			</span>
		</div>

		<div class="selectTemplateHeader" v-if="offerTemplates.length > 0">
			{{ lang.offers.selectTemplateHeader }}
		</div>

		<div class="templateRows">
			<div
				v-for="template in offerTemplates"
				:key="`CreateOfferFromTemplate-template-${template.id}`"
				class="templateRow buttonLike"
				:template="template"
				@click="templateClicked(template)"
			>
				<div class="templateRow-name">
					<img
						v-if="template.isNonsurplus"
						class="tag-button-inline"
						src="/app/img/tags/nonsurplus.png"
					/>
					{{ template.name }}
				</div>
				<div class="templateRow-bottomPart">
					<div
						v-bind:class="{
							'templateRow-price': true,
							nonsurplus: template.isNonsurplus,
						}"
					>
						{{ createPriceText(template.defaultPrice) }}
					</div>
					<div class="templateRow-description">{{ template.description }}</div>
				</div>
				<ArrowRight />
			</div>
		</div>
		<div class="dismissButton" @click="$destroy()">
			<CloseIcon />
		</div>
	</div>
</template>

<script>
import Plus from 'vue-material-design-icons/Plus.vue'
import SearchWeb from 'vue-material-design-icons/Magnify.vue'
import CloseIcon from 'vue-material-design-icons/Close.vue'
import ArrowBack from 'vue-material-design-icons/ArrowLeft.vue'
import ArrowRight from 'vue-material-design-icons/ChevronRight.vue'
import vueStore from '../es6/store.js'
import Vue from 'vue'
import { dismissableMixin } from '@resqclub/resq-components/mixins'

const CreateOfferFromTemplate = {
	mixins: [dismissableMixin],
	name: 'CreateOfferFromTemplate',
	props: [],
	data: () => ({
		searchString: '',
		lang: window.lang,
		profile: use.profile,
	}),
	computed: {
		offerTemplates() {
			let searchString = this.searchString.toLowerCase()

			// Can be null if offerTemplates haven't been fetched yet
			let offerTemplates = vueStore.state.offerTemplates

			if (offerTemplates) {
				// Copy before sorting
				offerTemplates = [...offerTemplates]

				// Sort order:
				// - primary, timesOfferedLately descending
				// - secondary, name ascending
				offerTemplates.sort((ot1, ot2) => {
					return ot2.timesOfferedLately - ot1.timesOfferedLately || ot1.name - ot2.name
				})

				return offerTemplates
					.filter(
						t =>
							t.name.toLowerCase().includes(searchString) ||
							t.description.toLowerCase().includes(searchString)
					)
					.map(offerTemplate => {
						let isNonsurplus = window.isOfferOrOfferTemplateNonsurplus(offerTemplate)
						let result = { ...offerTemplate, isNonsurplus }
						return result
					})
			} else {
				return []
			}
		},
	},
	mounted() {
		document.body.classList.add('scroll-freeze')
	},
	destroyed() {
		document.body.classList.remove('scroll-freeze')
		this.$emit('destroyed')
	},
	methods: {
		createPriceText(price) {
			return formatCurrency(price, use.profile.currency, true)
		},
		templateClicked(template) {
			this.$destroy()

			window.openCreateOfferDialog(template)
		},
		createNewOfferClicked() {
			this.$destroy()

			window.openCreateOfferFromScratchDialog()
		},
		backButtonClicked(event) {
			this.$destroy()
		},
	},
	components: {
		Plus,
		SearchWeb,
		CloseIcon,
		ArrowBack,
		ArrowRight,
	},
}
export default CreateOfferFromTemplate

export function openCreateOfferFromTemplatePopup(closeCallback) {
	let popup = new Vue({
		render: h =>
			h(CreateOfferFromTemplate, {
				on: {
					destroyed() {
						closeCallback && closeCallback()
					},
				},
			}),
	}).$mount()

	// without timeout, context menu would close because of a click
	setTimeout(() => {
		document.querySelector('body').appendChild(popup.$el)
		let createOfferFromTemplateInput = document.querySelector('.CreateOfferFromTemplate input')
		createOfferFromTemplateInput && createOfferFromTemplateInput.focus()
	}, 1)
}
</script>

<style lang="stylus" scoped>
@import '../css/variables.styl'

.CreateOfferFromTemplate {
	max-width: 500px
	box-shadow: 0 1px 10px 0px rgba(0, 0, 0, 0.12)
	border-radius: 4px
	overflow: visible
	background: white
	user-select: none
	position: absolute
	top: $small_topbar_height + 10px
	left: 50%
	right: auto
	max-width: calc(90% - 60px)
	width: 500px
}

@media screen and (max-width: 1200px) {
	.CreateOfferFromTemplate {
		left: auto
		right: 100px
	}
}

.mobileTopBar {
	display: none
}

.searchArea {
	position: relative

	.material-design-icon {
		position: absolute
		top: 14px
		left: 16px
		font-size: 22px
		color: #8e8e8e
		pointer-events: none
	}

	input {
		font-size: 14px
		width: 100%
		padding: 9px 10px 6px 50px
		border-bottom: none
		box-sizing: border-box
		height: auto
	}
}

.createNewOfferButton {
	text-align: center
	color: black
	font-size: 14px
	font-weight: bold
	padding: 15px
	padding-bottom: 13px // this just looks better
	background: #e1ff45 !important

	.material-design-icon {
		width: 1.5em
		transform: translateY(-1px) scale(1.5)
	}

	&:hover {
		box-shadow: inset 0 0 18px 0px #1b1b1b19
	}
}

.selectTemplateHeader {
	height: 40px
	line-height: 44px

	text-align: center
	text-transform: uppercase
	font-size: 13px
}

.templateRows {
	max-height: calc(100vh - 190px)
	overflow: auto
}

.buttonLike {
	cursor: pointer

	&:hover {
		background: #f7f7f7
	}
}

.templateRow {
	// padding-bottom: 13px; // bottom is smaller because line height causes space under the text
	// margin-bottom: 2px;
	padding: 14px 20px
	/* margin-bottom: 2px; */
	border-top: 2px solid #f9f9f9

	.material-design-icon {
		position: absolute
		visibility: hidden
	}
}

.templateRow-name {
	// font-weight bold
	line-height: 1.2rem
	font-size: 14px
	color: black // default color is not quite black
}

.templateRow-bottomPart {
	text-overflow: ellipsis
	overflow: hidden
	white-space: nowrap
	margin-top: 2px
	color: gray
	font-size: 12px
	line-height: 1rem
}

.templateRow-bottomPart > * {
	display: inline
	text-overflow: ellipsis
	overflow: hidden
	white-space: nowrap
}

.templateRow-description {
	margin-left: 7px
}

.dismissButton {
	width: 50px
	height: 50px
	border-radius: 50%
	background: white
	box-shadow: 0 2px 3px 1px #00000036
	position: absolute
	right: -70px
	top: 0px
	text-align: center
	font-size: 24px
	cursor: pointer

	> .material-design-icon {
		position: relative
		top: 50%
		transform: translateY(-50%)

		>>> svg {
			bottom: 0
		}
	}

	&:hover {
		background: #fafafa
	}
}

@media screen and (max-width: $max_mobile_top_bar_width) {
	.CreateOfferFromTemplate {
		left: auto
		right: 100px
		top: $big_topbar_height + 10px
	}

	.templateRows {
		max-height: calc(100vh - 250px)
	}
}

// Mobile layout
@media screen and (max-width: 800px) {
	.CreateOfferFromTemplate {
		position: fixed
		top: 0px
		left: 0px
		right: 0px
		bottom: 0px
		width: auto
		height: auto
		max-width: none
		z-index: 4000
		box-shadow: none
		display: flex
		flex-direction: column
	}

	.mobileTopBar {
		display: block
		background: #1bcbac
		height: 60px
		text-align: center
		position: relative

		.brandIcon {
			position: absolute
			top: 20px
			height: 20px
			left: 50%
			transform: translateX(-50%)
		}

		.backButton {
			display: inline-block
			position: absolute
			padding: 15px 20px
			left: 0
			top: 0
			height: 100%
			color: #fff
			font-size: 24px
			cursor: pointer
		}
	}

	.dismissButton {
		display: none
	}

	.searchArea {
		background: #1bcbac
		padding: 15px
		padding-top: 0
		height: 60px // must be the same size as top bar row

		.material-design-icon, input, input::placeholder {
			color: #ffffffb5
			left: 31px
			top: 9px
		}

		input {
			background: #19b89c
			padding: 4px 30px 2px 50px
			border-radius: 22px
			height: auto
		}
	}

	.templateRows {
		max-height: none
		flex: 1
	}

	.templateRow {
		position: relative
		padding-right: 50px

		.material-design-icon {
			visibility: visible
			color: #666
			font-size: 24px
			right: 15px
			top: calc(50% - 3px)
			transform: translateY(-50%)
		}
	}
}
</style>
