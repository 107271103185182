import vueStore from './store'

let orderNotificationAudio = null
let playAfterInit = false
let inited = false

// Used only for UI. If true, we are sure audio can be played.
let ableToPlay = false
let metadataLoaded = false

// This should be called automatically by page init function (js/init.js)
export function initOrderAlertSound() {
    orderNotificationAudio = new Audio('/provider/sound/gentleup.mp3')
    orderNotificationAudio.addEventListener('loadedmetadata', () => {
        metadataLoaded = true
    })
    if (orderNotificationAudio.readyState >= 4) {
        // We got the file from cache
        inited = true
    } else {
        const canPlayThrough = function canPlayThrough() {
            inited = true

            orderNotificationAudio.removeEventListener('canplaythrough', canPlayThrough)
            if (playAfterInit) {
                playOrderAlert()
            }
        }
        orderNotificationAudio.addEventListener('canplaythrough', canPlayThrough)
    }
}

export function setOrderAlerts(enabled) {
    window.store.set('playNewOrderSound', enabled)
    if (enabled) {
        playOrderAlert()
    } else {
        stopOrderAlert()
    }
}

function playPromiseful(audio) {
    return Promise.resolve().then(() => audio.play())
}

export function isOrderAlertsOn() {
    return window.store.get('playNewOrderSound') === 'true'
}

export function playOrderAlert() {
    function playFailed(e) {
        vueStore.commit('setAudioPlayingFailed', true)
        window.getRequest('provider/log', { msg: 'audio inited and playable but threw error', ableToPlay, error: e && e.message })
    }
    if (!isOrderAlertsOn()) {
        return
    }
    if (inited) {
        if (!isNaN(orderNotificationAudio.duration) && metadataLoaded) {
            try {
                orderNotificationAudio.currentTime = 0
            } catch (e) {
                // TODO remove if found harmless
                window.getRequest('provider/log', { msg: 'Possible IE11 error handled 1', ableToPlay, error: e && e.message })
            }
            playPromiseful(orderNotificationAudio).catch(playFailed)
        } else {
            // audio is not yet loaded.
            // audio will play after it is loaded for the first time. (canPlayThrough callback)
            // just ignore this
            window.getRequest('provider/log', { msg: 'audio inited but not playable', ableToPlay })
        }
    } else {
        playAfterInit = true
    }
}
export function stopOrderAlert() {
    if (inited) {
        orderNotificationAudio.pause()
    }
}

// .play() can only be called after the first user interaction. So at that point we try to call it,
// trying not to produce a sound, to figure out if it works.
// TODO document some cases where .play() would not work! Older browsers?
function afterFirstUserInteraction() {
    if (ableToPlay) {
        return
    }

    try {
        if (metadataLoaded) {
            orderNotificationAudio.currentTime = 0
        }
    } catch (e) {
        // TODO remove if found harmless
        window.getRequest('provider/log', { msg: 'Possible IE11 error handled 2', ableToPlay, error: e && e.message })
    }

    playPromiseful(orderNotificationAudio).then(() => {
        orderNotificationAudio.pause()
        ableToPlay = true
        vueStore.commit('setAbleToPlayAudio', ableToPlay)
    })
}

function userInteractionDone() {
    // Fix "Client error: Unable to set property 'currentTime' of undefined or null reference"
    if (!orderNotificationAudio) {
        return
    }

    if (ableToPlay) {
        window.removeEventListener('click', userInteractionDone)
        window.removeEventListener('mousedown', userInteractionDone)
        return
    }
    afterFirstUserInteraction()
}

// Click also handles touch events
window.addEventListener('click', userInteractionDone)
window.addEventListener('mousedown', userInteractionDone)
