import { el, mount } from 'redom'
import { mapStylesForAdmin, mapStylesForProvider } from './mapStyles'

let geocoder

export default class MapLocationSelector {
    // Options:
    //
    // zoom, latitude, longitude: passed to Map
    // bounds: { minLatitude, maxLatitude, minLongitude, maxLongitude } - set initial bounds
    // centerChanged: callback called with (lat, long) when the map moves
    // context: 'admin' | 'provider', determines the color scheme
    constructor(options = {}) {
        this.el = el('div.mapLocationSelector')
        this.options = options
        this.options.context = this.options.context || 'admin'

        wait.googleMaps.then(() => {
            setTimeout(() => {
                // Wait until this.el is in DOM.
                this.initMap()
                mount(this.el, el('img.mapLocationCenterPin',
                    { src: options.centerPinImageUrl || '/common/components/map-location-selector-pin.png' }))
            }, 0)
        })
    }

    initMap() {
        const { zoom, latitude, longitude, bounds, centerChanged, context } = this.options

        if (latitude != null && longitude != null && bounds) {
            throw new Error('Only give latitude & longitude or bounds, but not both.')
        }

        let styles
        if (context === 'admin') {
            styles = mapStylesForAdmin
        } else if (context === 'provider' || context === 'enterprise') {
            styles = mapStylesForProvider
        }

        this.map = new window.google.maps.Map(this.el, {
            center: { lat: latitude || 0, lng: longitude || 0 },
            zoom: zoom || 18,
            styles,
            scrollwheel: false,
            streetViewControl: false,
            fullscreenControl: false
        })

        if (bounds) {
            this.map.fitBounds({
                north: bounds.maxLatitude,
                south: bounds.minLatitude,
                west: bounds.minLongitude,
                east: bounds.maxLongitude
            })
        }

        this.map.addListener('center_changed', () => {
            const center = this.map.getCenter()
            if (centerChanged)
                centerChanged(center.lat(), center.lng() % 360)
        })
    }

    search(address, callback) {
        if (!geocoder)
            geocoder = new window.google.maps.Geocoder()

        const self = this

        geocoder.geocode({
            address
        }, function(results, status) {
            if (status === 'OK') {
                const location = results[0].geometry.location
                self.setCenter(location.lat(), location.lng())
                self.map.setZoom(17)

                if (callback)
                    callback(results[0])
            } else {
                console.log('Geocoder fail:', status)
            }
        })
    }

    setCenter(latitude, longitude) {
        if (!this.map) {
            log('ERROR: setCenter() called too early')
            return
        }
        this.map.setCenter({ lat: latitude, lng: longitude })
    }
}

window.googleMapsReceived = function() {
    waitNoMore.googleMaps()
}
