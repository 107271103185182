//
//
//
//
//
//
//

export default {
	name: 'InsightsPublishCell',
	props: ['data'],

}
