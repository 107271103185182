let loginCookieChangeInterval

// This is a duplicated constant with backend, and is meant to match the value in
// constants/index.ts
const USER_TYPE = Object.freeze({
  Admin: 'admin',
  Provider: 'provider',
  Enterprise: 'enterprise',
  User: 'user',
});

const _getProfileData = (endpoint) => window.getRequest(endpoint).then((response) => JSON.parse(response))

const _fetchProviderName = () => _getProfileData('provider/myInfo').then((response) => response.myInfo.name)
const _fetchEnterpriseName = () => _getProfileData('enterprise/profile').then((response) => response.profile.name)
const _fetchUserName = () =>_getProfileData('app/profile').then((response) => response.profile.fullName)

const COOKIE_LISTENER_INTERVAL_MS = 3000

const _AUTHENTICATION_TOKEN = {
    [USER_TYPE.Provider]: {
        path: '/provider/',
        userTypeName: 'partner',
        eventType: 'PROVIDER_TOKEN_CHANGED',
        fetchName: _fetchProviderName,
    },
    [USER_TYPE.Enterprise]: {
        path: '/enterprise/',
        userTypeName: 'enterprise',
        eventType: 'ENTERPRISE_TOKEN_CHANGED',
        fetchName: _fetchEnterpriseName,
    },
    [USER_TYPE.User]: {
        path: '/app/',
        userTypeName: 'user',
        eventType: 'USER_TOKEN_CHANGED',
        fetchName: _fetchUserName,
    },
}

const _watchChangesOnLoginCookieFor = (userType, environment) => {
    const lastLoginId = window.getLoginIdFromCookie(userType, environment)
    const { eventType } = _AUTHENTICATION_TOKEN[userType]
    const event = new Event(eventType)

    return () => {
        const currentLoginId = window.getLoginIdFromCookie(userType, environment)
        if (currentLoginId !== lastLoginId) {
            window.dispatchEvent(event)
        }
    }
}

const _handleAlertAfterCookieChange = (userType) => {
    const { eventType, userTypeName } = _AUTHENTICATION_TOKEN[userType]

    if (!window.sessionStorage.getItem(eventType)) {
        return
    }

    _AUTHENTICATION_TOKEN[userType].fetchName().then((name) => {
        const { title, titleDefault, explanation, action } = window.lang.login.tokenChanged
        const { formatText } = window

        const alertTitle = name
            ? formatText(title, name)
            : formatText(titleDefault, userTypeName)

        const message = `<p>${formatText(explanation, userTypeName)}</p>`+
			`<p>${formatText(action, userTypeName)}</p>`

        window.showAlert(message, alertTitle, null, true)
        window.sessionStorage.removeItem(eventType)
    })
}

const _addListenersForCookieType = userType => {
    const { eventType } = _AUTHENTICATION_TOKEN[userType]

    window.addEventListener('load', () => {
        _handleAlertAfterCookieChange(userType)

        wait.env.then(({ ENVIRONMENT_NAME }) => {
            loginCookieChangeInterval = setInterval(
                _watchChangesOnLoginCookieFor(userType, ENVIRONMENT_NAME),
                COOKIE_LISTENER_INTERVAL_MS
            )
        })
    })

    window.addEventListener(eventType, () => {
        window.sessionStorage.setItem(eventType, 'true')
        clearInterval(loginCookieChangeInterval)
        window.location.reload()
    })
}

export const listenToChangesInProviderCookie = () => _addListenersForCookieType(USER_TYPE.Provider)
export const listenToChangesInEnterpriseCookie = () => _addListenersForCookieType(USER_TYPE.Enterprise)
export const listenToChangesInUserCookie = () => _addListenersForCookieType(USER_TYPE.User)

