
// Admin styles:
// - transit markers removed
// - points of interest de-emphasized heavily (the assumption being we can potentially show many
//   ResQ partners on the map one day)
export const mapStylesForAdmin = [
    {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [
            {
                saturation: -50,
            },
            {
                lightness: 75,
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [
            {
                saturation: -50,
            },
            {
                lightness: 50,
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
]

// Styles for partner and enterprise:
// - transit markers removed
// - points of interest de-emphasized slightly (so that the partner can align their location with
//   their own, or other venues on Google Maps)
export const mapStylesForProvider = [
    {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [
            {
                saturation: -10,
            },
            {
                lightness: 40,
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [
            {
                saturation: -10,
            },
            {
                lightness: 40,
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
]
