import Vue from 'vue'
import { isItSafeToRefresh } from './activityTracker'

let firebaseDatabase = null

import vueStore from './store'
import { playOrderAlert } from './orderAlert'

function reloadPageAsSoonAsPossible() {
    console.log(`Firebase: profile changed; reloading page when the user is looking away`)
    setInterval(() => {
        if (isItSafeToRefresh()) {
            window.location.reload()
        }
    }, 1000)
}

const actionsToDispatchWhenTabGoesActive = []

function dispatchActionWhenTabIsActive(action) {
    // Add to queue
    if (!actionsToDispatchWhenTabGoesActive.includes(action)) {
        actionsToDispatchWhenTabGoesActive.push(action)
    }

    // Dispatch queue if browser is active
    if (window.isBrowserTabActive) {
        dispatchActionQueue()
    }
}

function dispatchActionQueue() {
    for (const action of actionsToDispatchWhenTabGoesActive) {
        vueStore.dispatch(action)
    }
    actionsToDispatchWhenTabGoesActive.length = 0
}

// When tab comes back online, dispatch the action queue
window.addEventListener('focus', dispatchActionQueue)

// When we have profile, we are sure that we have env.
wait.profile.then(function () {
    let latestOrderTimeUpdated = false
    let profileUpdatedUpdated = false
    let refreshRequestedUpdated = false

    if (window.firebase) {
        window.firebase.initializeApp({ databaseURL: use.env.FIREBASE.databaseUrl })
        const db = window.firebase.database()
        if (use.env.ENVIRONMENT_NAME === 'local') {
          console.warn('Using firebase emulator')
          db.useEmulator("localhost", 9000);
        }
        firebaseDatabase = db
        // get this already so that page would load faster
        // providerEmitter.getAllCompactProviders();

        firebaseDatabase.ref(use.env.FIREBASE.providerMetadataPath + 'orderListUpdated').on('value', () => {
            console.log('Firebase: orders updated')

            dispatchActionWhenTabIsActive('updateOrders')
        })

        firebaseDatabase.ref(use.env.FIREBASE.providerMetadataPath + 'latestOrderTime').on('value', () => {
            if (latestOrderTimeUpdated) {
                playOrderAlert()
            }

            latestOrderTimeUpdated = true
        })

        firebaseDatabase.ref(use.env.FIREBASE.providerMetadataPath + 'offerListUpdated').on('value', () => {
            console.log('Firebase: offers updated')

            dispatchActionWhenTabIsActive('updateOffers')

            // TODO: Store offers on VueStore
        })

        firebaseDatabase.ref(use.env.FIREBASE.providerMetadataPath + 'templateListUpdated').on('value', () => {
            console.log('Firebase: offers templates updated')

            dispatchActionWhenTabIsActive('updateOfferTemplates')

            // TODO: Store offers on VueStore
        })

        firebaseDatabase.ref(use.env.FIREBASE.providerMetadataPath + 'profileUpdated').on('value', (val) => {
            console.log('Firebase: profile updated')

            if (profileUpdatedUpdated) {
                reloadPageAsSoonAsPossible()
            }

            profileUpdatedUpdated = true
        })

        firebaseDatabase.ref(use.env.FIREBASE.providerMetadataPath + 'refreshRequested').on('value', () => {
            console.log('Firebase: refresh requested')

            if (refreshRequestedUpdated) {
                reloadPageAsSoonAsPossible()
            }

            refreshRequestedUpdated = true
        })

        firebaseDatabase.ref('.info/connected').on('value', function(connectedSnap) {
            vueStore.commit('setConnectionProblems', !connectedSnap.val())
        })
    }
})
