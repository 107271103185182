<template>
	<div class="InsightsDateCell">
		<img :class="{ visible: data.isPublished }" class="publishedIcon" src="/provider/img/icon_public.svg" alt="Public review" />
		<div class="dateLabel">{{ data.dateString }}</div>
	</div>
</template>

<script>
	export default {
		name: 'InsightsPublishCell',
		props: ['data'],

	}
</script>

<style lang="stylus" scoped>
	.publishedIcon {
		width: 16px;
		margin-right: 4px;
		float: left;
		opacity: 0.0;
		margin-top: 4px;
	}

	.visible {
		opacity: 1.0;
	}

	.dateLabel {
		float: left;
	}

</style>
