<!--
    This component is needed only because container of this stuff isn't a vue component.
    When we vueify things more, just use <Button><Plus/>Text</Button> directly.
-->

<template>
	<Button class="NewButton" @click="clicked">
		<Plus />
		<slot />
	</Button>
</template>

<script>
import { Button } from '@resqclub/resq-components'
import Plus from 'vue-material-design-icons/Plus.vue'

export default {
	name: 'NewButton',
	props: [],
	data: () => ({}),
	computed: {},
	methods: {
		clicked() {
			this.$emit('click')
		},
	},
	components: {
		Button,
		Plus,
	},
}
</script>

<style lang="stylus" scoped>
</style>
