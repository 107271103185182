import OfferTime from './offerTime'

export class TimeValidator {
    constructor(options) {
        this.validators = []
        this.inputOfferTime = new OfferTime()

        if (options) {
            this._min = options.min || null
            this._max = options.max || null
            this._now = options.now || null //deprecated
            this._nowTime = options.nowTime || null
        }
    }

    addValidator(validator) {
        if (typeof validator === 'function') {
            this.validators.push(validator)
        } else if (typeof validator === 'string') {
            this.validators.push(this[validator])
        } else {
            console.error('no proper validator provided')
        }
        return this
    }

    validateAbsoluteBounds() {
        if (!this.max || !this.min) {
            return this
        }

        if (this.inputOfferTime.getValue() > this.max.getValue()) {
            this.inputOfferTime.fromDate(this.max)
        }

        return this

    }

    // Ensures that this.inputOfferTime is <= this._nowTime
    validateFuture() {
        if (!this._nowTime) {
            console.error('no now defined for validator', this)
            return this
        }

        if (this.inputOfferTime.getValue() < this._nowTime.getValue()) {
            log('!!! validateFuture set something')
            this.inputOfferTime.setValue(this._nowTime.getValue())
        }

        return this
    }

    getVal() {
        return this.val
    }

    get val() {
        for (var i in this.validators) {
            var validator = this.validators[i]
            validator.call(this)
        }
        return this.inputOfferTime && this.inputOfferTime.isValid() ? this.inputOfferTime.getTime() : null
    }

    set val(val) {
        if (val instanceof OfferTime) {
            console.error('tried to set validator value with OfferTime', val)
            return
        }
        this._parseValue(val)
        this._val = null
    }

    get min() {
        return new OfferTime().setTime(this._min)
    }

    get max() {
        return new OfferTime().setTime(this._max)
    }

    _parseValue(val, noUpdate) {
        const inputOfferTime = new OfferTime()

        if (!val || val.length === 0) {
            console.trace()
            console.error('no value found', val)
            return
        }

        let hours
        let minutes
        let match = val.match(/^(\d\d?)$/)

        if (match) {
            hours = Number(match[1])
            minutes = 0
        } else {
            match = val.match(/^(\d\d?)[ _.:,-]?(\d\d)$/)

            if (match) {
                hours = Number(match[1])
                minutes = Number(match[2])
            }
        }

        if (hours !== undefined && minutes !== undefined) {

            inputOfferTime.setHours(hours)
            inputOfferTime.setMinutes(minutes)
            if (inputOfferTime.isValid() && !noUpdate) {
                this.inputOfferTime.setHours(inputOfferTime.getHours())
                this.inputOfferTime.setMinutes(inputOfferTime.getMinutes())
            }
        }
    }

    setVal(val) {
        this.val = val

        return this
    }

    getValFromDate(date) {
        return '' + date.getHours() + ':' + TimeValidator.pad2(date.getMinutes())
    }

    get date() {
        console.error('deprecated date() used')
        console.trace()
        return this._parseValue(this.val)
    }

    getDate() {
        return this.date
    }

    get offerTime() {
        return this.inputOfferTime && this.inputOfferTime.isValid() ? this.inputOfferTime : null
    }

    getOfferTime() {
        return this.offerTime
    }

    static pad2(input) {
        input = '' + input
        if (input.length < 2)
            return '0' + input
        return input

    }

    static formatDate(date) {
        const res = TimeValidator.pad2(date.getHours()) + window.separator + TimeValidator.pad2(date.getMinutes())
        return res
    }

    static dateValid(date) {
        return !isNaN(date.getMonth())
    }
}
