//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vueStore from '../es6/store'
import { dismissableMixin } from '@resqclub/resq-components/mixins'

export default {
	name: 'ContextMenu',
	mixins: [dismissableMixin],
	props: {
		links: {
			type: Array, // [{ label: 'Click me', callback: () => doSomething() }]
			default: () => [],
		},
		css: String,
		onClose: Function
	},
	data: () => ({}),
	// mounted() {
	// 	window.addEventListener('click', () => {
	// 		this.$el.remove()
	// 		this.$destroy()
	// 	})
	// },
	computed: {
		filteredLinks() {
			return this.links.filter(link => {
				if (!link) {
					return false
				}

				if (link.showWhen) {
					return link.showWhen(vueStore.state, vueStore.getters)
				}

				return true
			})
		}
	},
	methods: {
		isLinkDisabled(link) {
			return !link.callback && !link.href
		},
		itemClicked(link) {
			this.$emit('close')
			if (link.callback) {
				link.callback()
			}

			if (!this.isLinkDisabled(link)) {
				this.$destroy()
			}
		},
	},
	components: {},
	beforeDestroy() {
		this.onClose && this.onClose()
	},
}
