import InsightsView from '../components/view/InsightsView.vue'
import Store from './store'

let visibleComponent = null

export function initRouteManager(defaultTab) {
    const routes = {
        offers: {
            component: null,
            creator: window.createOfferView,
            title: lang.p.tab.allOffers,
        },
        templates: {
            component: null,
            creator: window.createTemplateView,
            title: lang.p.tab.templates,
        },
        orders: {
            component: null,
            creator: window.createOrderView,
            title: lang.p.tab.orders,
        },
        insights: {
            component: InsightsView,
            title: lang.p.tab.feedback,
        },
        stats: {
            component: null,
            creator: window.createStatView,
            title: lang.p.tab.stats,
        },
        // TODO delete this - maintained for a while so that possible old links to #myInfo
        // still work
        myInfo: {
            component: null,
            creator: window.createMyInfoView,
            title: lang.p.tab.myInfo,
        },
        profile: {
            component: null,
            creator: window.createMyInfoView,
            title: lang.p.tab.myInfo,
        },
        settings: {
            component: null,
            creator: window.createSettingsView,
            title: lang.p.tab.settings,
        },
        default: {
            redirect: defaultTab,
        },
    }

    const hashChanged = () => {
        const hash = window.location.hash.slice(1) || 'default'

        const route = routes[hash] || routes.default

        if (route.redirect) {
            log('Redirecting to ' + route.redirect)
            window.location.hash = route.redirect
            return
        }
        if (route.creator) {
            // Support legacy way of doing tabs & navigation
            // TODO Remove this when there are only Vue views left
            window.updateCurrentView = function(updateViewCallback) {
                window.clearViewContent()
                route.creator(updateViewCallback)
            }

            if (visibleComponent) {
                visibleComponent.$destroy()
                visibleComponent = null
            }
            route.creator()
        } else if (route.component) {
            if (visibleComponent) {
                visibleComponent.$destroy()
                visibleComponent = null
            }

            window.setInfoText()
            const content = window.clearViewContent()
            content[0].appendChild(document.createElement('div'))
            visibleComponent = new window.Vue({
                el: '#content > div',
                render: h =>
                    h(route.component, {
                        props: {},
                    }),
            })
        }

        Store.dispatch('setRoute', { route, page: hash })
    }

    window.addEventListener('hashchange', hashChanged)
    hashChanged()
}
