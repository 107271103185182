<template>
	<div class="InsightsTableRatingCell" :class="'InsightsTableRatingCell-' + data">
		<img v-if="data == 'good'" src="/common/img/review-emoji_good.svg" alt="happy face" />
		<img v-else-if="data == 'okay'" src="/common/img/review-emoji_okay.svg" alt="okay face" />
		<img v-else-if="data == 'bad'" src="/common/img/review-emoji_bad.svg" alt="sad face" />
		{{ lang.review.rating[data] || data }}
	</div>
</template>

<script>
export default {
	name: 'InsightsTableRatingCell',
	props: ['data'],
	data: () => ({
		lang: window.lang,
	}),
	computed: {},
	methods: {},
	components: {},
}
</script>

<style lang="stylus" scoped>
.InsightsTableRatingCell {
	img {
		width: 18px
		vertical-align: middle
		position: relative
		top: -2px
		margin-right: 5px
	}

	white-space: nowrap
}

.InsightsTableRatingCell-bad {
	color: red
}

.InsightsTableRatingCell-problem {
	color: red
}

.InsightsTableRatingCell-problem::before {
	content: '❗'
	// color: red
}

</style>
