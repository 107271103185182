<template>
    <a :href="data.href" class="TopBarTab" :class="{selected: isActive,
    hasBadge: showNumberBadge, onlyDesktopWidths: !!data.onlyDesktopWidths, [data.class]: !!data.class}">
		<div class="bindHtml" v-if="data.extraHtml" v-html="data.extraHtml"></div>
        <div class="TopBarTab-text">
            {{data.label}}
            <div v-if="showNumberBadge" class="numberBadge">
                <span>{{numberBadgeValue}}</span>
            </div>
        </div>
    </a>
</template>

<script>
import Store from '../es6/store'
export default {
    name: 'TopBarTab',
    props: {
        data: Object // { label, callback, selected, href, numberBadgeKey, class, extraHtml }
    },
    data: () => ({
    }),
    computed: {
        isActive() {
            return '#' + Store.state.currentPage === this.data.href
        },
        numberBadgeValue() {
            return Store.state[this.data.numberBadgeKey]
        },
        showNumberBadge() {
            return this.data.numberBadgeKey && this.numberBadgeValue > 0
        }
    },
    methods: {
    },
    components: {
    }
}
</script>

<style lang="stylus" scoped>
@import "../css/variables.styl"

a.TopBarTab {
    position relative
    display inline-block
    height 100%
    padding: 0 25px
    color: white !important // a:hover is set to be resq green color
    text-transform uppercase
    cursor: pointer

    border-bottom: 2px solid transparent

    overflow: hidden;

    &:hover {
        background rgba(0, 0, 0, 0.02)
    }

    &.selected {
        background rgba(0, 0, 0, 0.1)
        // color: #dcff3d !important // a:hover is set to be resq green color
        border-color: #dcff3d
    }

    &.hasBadge {
        padding-right: 45px
    }

	@media screen and (max-width: 1050px) {
		&.onlyDesktopWidths {
			display none
		}
	}
}

.TopBarTab-text {
    position relative
    display inline-block
    top: calc(50% + 2px)
    transform translateY(-50%)
    max-width: 140px
}

$badgeHeight = 20px

.numberBadge {

    background white
    color: #108D76
    font-weight bold
    font-size: 11px
    position absolute

    // I managed to get this work with desktop and mobile layout this way
    top: calc(50% - 2px)
    right: -30px
    transform translateY(-50%)

    height: $badgeHeight
    width: $badgeHeight

    border-radius: 50%

    span {
        position: relative;
        top: 4px;
    }
}

@media screen and (max-width 650px) {
    a.TopBarTab {
        font-size: calc(4px + 1.5vw)
        padding: 0 2vw
        // font-size: 4vw
    }
}
@media screen and (max-width $max_mobile_top_bar_width) {
    a.TopBarTab {
        // Flexbox makes it more beautiful but this works also without flex
        flex: 1
    }
}


div.bindHtml {
	position absolute
	left: 0
	right: 0
	top: 0
	bottom: 0
}
</style>
