export default class OfferTime {

    constructor(options) {
        //console.log('OfferTime constructor()');
        this.__date = null

        if (options instanceof OfferTime) {
            this.setValue(options.getValue())
        }
    }

    init(options) {
        //Set off a fresh epoch at 1.1.1970
        this._date = new Date(0)
    }

    /**
	 * return the internal date of the offertime.
	 * Note that this is not real world date
	 * @returns {null|*}
	 * @private
	 */
    get _date() {
        if (!this.__date) {
            //throw "OfferTime read while not initialized";
            console.error('OfferTime read while not initialized', this)
            console.trace()
        }
        return this.__date
    }

    set _date(date) {
        //console.log("setting date", date)
        this.__date = date
    }

    /**
	 * get time in format HH:MM
	 */
    getTime() {
        //console.log("getTime", this)
        return OfferTime.pad2(this._date.getHours()) + ':' + OfferTime.pad2(this._date.getMinutes())
    }

    static pad2(value) {
        if (parseInt(value) > 9) {
            return '' + parseInt(value)
        }
        return '0' + parseInt(value)
    }

    /**
	 * Set the time from a date object. Only Hours and minutes used,
	 * everything else will be discarded
	 * @param date
	 * @returns {OfferTime}
	 */
    fromDate(date) {
        this.init()
        this.setHours(date.getHours())
        this.setMinutes(date.getMinutes())
        this.setValid(true)
        return this
    }

    /**
	 * set time in format HH:MM
	 * @param value
	 */
    setTime(value) {
        //		console.warn("setTime", value)
        this.init()
        const parts = value.split(':')
        if (value.length > 1) {
            this.setHours(parseInt(parts[0])).setMinutes(parseInt(parts[1]))
            this.setValid(true)
        } else {
            this.setValid(false)
        }
        return this
    }

    setValid(bool) {
        this._valid = !!bool
    }

    isValid() {
        return this._valid
    }

    /**
	 * set minutes after epoch
	 * @param value
	 */
    setValue(minutes) {
        this.init()
        this._date.setTime(minutes * 60 * 1000)
        return this
    }

    /**
	 * Get minutes after epoch
	 */
    getValue() {
        return this._date.getTime() / (1000 * 60)
    }

    /**
	 * get minutes component of this time
	 */
    getMinutes() {
        return this._date.getMinutes()
    }

    /**
	 * set minutes component
	 * @param minutes
	 */
    setMinutes(minutes) {
        if (!this.__date) {
            this.init()
        }
        this.__date.setMinutes(minutes)
        //console.log("setMinutes", minutes, this._date)
        this.setValid(true)
        return this
    }

    /**
	 * get hours component
	 */
    getHours() {
        return this._date.getHours()
    }

    /**
	 * set hours component
	 * @param hours
	 */
    setHours(hours) {
        if (!this.__date) {
            this.init()
        }
        let extraHours = 0

        if (hours < 6) {
            extraHours = 24
        }

        this.__date.setHours(hours + extraHours)
        this.setValid(true)
        return this
    }

}
