import vueStore from './store'

window.offersStream = new window.Stream(vueStore.state.offers)
window.offerTemplatesStream = new window.Stream(vueStore.state.offerTemplates)
window.ordersStream = new window.Stream(vueStore.state.orders)
window.connectionProblemsStream = new window.Stream(vueStore.state.connectionProblems)

export default {
    name: 'VintageUpdaterBasedOnFirebase',
    computed: {
        offers() {
            return vueStore.state.offers
        },
        offerTemplates() {
            return vueStore.state.offerTemplates
        },
        orders() {
            return vueStore.state.orders
        },
        connectionProblems() {
            return vueStore.state.connectionProblems
        }
    },
    watch: {
        offers(offers) {
            window.offersStream.push(offers)
        },
        offerTemplates(offerTemplates) {
            window.offerTemplatesStream.push(offerTemplates)
        },
        orders(orders) {
            window.ordersStream.push(orders)
        },
        connectionProblems(connectionProblems) {
            window.connectionProblemsStream.push(connectionProblems)
        },
    }
}
