<script>
let langRoot = {};

const traverse = (root, key) => {
  const path = key.split('.');
  let node = root;

  for (let i = 0; i < path.length; i++) {
    const keyPart = path[i];
    node = node[keyPart];
    if (node === undefined) {
      console.log(`Lang: could not find string with id "${key}"`);
      return null;
    }
  }

  if (typeof node !== 'string') {
    console.log(`Lang: the string with id "${key}" is not a string`);
    return null;
  }

  return node;
};

const findLangKey = key => traverse(langRoot, key);

export default {
  props: {
    /**
     * `id` specifies a key for the text content. The content is read from `window.lang.<id>`.
     * `<Lang>` will not crash even if you try to use a key that does not exist - it will render
     * an error instead.
     */
    id: { type: String, required: true },
  },
  render(createElement) {
    const textContent = this.content;

    const debugAttrs = { attrs: { 'data-lang-key': this.id } };

    // Case 1: no such key. Return an error instead.
    // Probably could do something else in production.
    if (textContent === null) {
      return createElement('span', { class: 'error' }, this.error);
    }

    // If content contains one of these, we inject a thing from a slot instead
    const PLACEHOLDER_REGEX = /(?:%@|%ld|%f|%F)/;
    const splitContent = textContent.split(PLACEHOLDER_REGEX);

    const requiredSlots = splitContent.length - 1;

    // Case 2: simple key, no interpolation.
    if (requiredSlots === 0) {
      return createElement('span', debugAttrs, this.content);
    }

    // Case 3: one placeholder - we use the default slot or slot number 0,
    // whichever is the first one we find
    if (requiredSlots === 1) {
      const slot = this.$slots.default || this.$slots[0];
      return slot
        ? createElement('span', debugAttrs, [splitContent[0], slot, splitContent[1]])
        : createElement('span', { class: 'error' }, `[Missing default slot or slot 0 for key ${this.id}]`);
    }

    // Case 4: two or more placeholders. Require as many slots as there were placeholders.
    // Handle errors in case slots are missing.
    const resultParts = [];
    let slotIdx = 0;
    for (let i = 0; i < splitContent.length; i++) {
      resultParts.push(splitContent[i]);
      if (i !== splitContent.length - 1) {
        const currentSlot = this.$slots[slotIdx];
        if (!currentSlot) {
          console.log(`Lang: the string to be interpolated needs ${splitContent.length - 1} slots but slot ${slotIdx} is missing`, JSON.stringify({
            id: this.id,
            textContent,
            slots: this.$slots
          }));

          return createElement('span', { class: 'error' }, `[Missing slot ${slotIdx} for key ${this.id}]`);
        }
        slotIdx++;
        resultParts.push(currentSlot);
      }
    }

    // Render function can only return one node so we need to wrap it inside a <span>
    return createElement('span', debugAttrs, resultParts);
  },

  computed: {
    error() {
      return this.content === null
        ? `[Invalid language key ${this.id}]`
        : null;
    },
    content() {
      return findLangKey(this.id)
    },
  },
  beforeMount() {
    window.wait.lang.then(() => {
      langRoot = window.lang;
    });
  }
}
</script>

<style lang="stylus" scoped>
span.error {
  font-weight: bold
  color: red
}
</style>
