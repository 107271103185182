export function getProviderData(key) {
    return window.getRequest(`provider/data/${key}`).then(result => {
        return JSON.parse(result).value
    })
}

export function setProviderData(key, value, expiresAt) {
    return window.postRequest(`provider/data/` + key, {
        value,
        expiresAt
    })
}

// Essentially same as node.isConnected, but supported in IE.
// Loop through node's ancestors to find out if it's attached somewhere
// under document.body.
export function isDomNodeConnected(node) {
    while (node) {
        if (node === document.body) {
            return true
        }
        node = node.parentNode
    }
    return false
}
