import {TimeValidator} from './timeValidator.js'
import OfferTime from './offerTime'

export class TimeBar {

  constructor(options) {
    this.el = $('<div>').addClass('timeBar')
    this.backgroundEl = $('<div>').addClass('background')
    this.barEl = $('<div>').addClass('bar')
    this.ticks = $('<div>').addClass('ticks')

    this.el.append(this.backgroundEl)
    this.el.append(this.barEl)
    this.el.append(this.ticks)

    //this is the range of times displayed
    this.options = {
      min: '06:00',
      max: '04:00',
      ticks: 6
    }

    $.extend(this.options, options)

    this.minOfferTime = new OfferTime().setTime(this.options.min)
    this.maxOfferTime = new OfferTime().setTime(this.options.max)

    //bar length in minutes
    const barLength = (this.maxOfferTime.getValue() - this.minOfferTime.getValue())

    //console.log("timeBar length", barLength);

    //set tick position and label
    for (let i = 0; i < barLength; i = i + 60) {

      const tick = $('<div>').addClass('tick')
      const tickLeft = (i / barLength) * 100
      const tickOfferTime = new OfferTime(this.minOfferTime)

      tickOfferTime.setValue(tickOfferTime.getValue())
      tickOfferTime.setMinutes(i)

      const tickHour = TimeValidator.pad2(tickOfferTime.getHours())

      tick.text(tickHour)

      tick.css({
        left: 'calc(' + tickLeft + '% - 5px)'
      })

      //don't bother displaying every tick
      if ((i % (60 * 4) === 0)) {
        this.ticks.append(tick)
      }

      //console.log("tick at", i, (i) % (60 * 3))
    }

    if (options.start && options.end) {
      //console.log("setting positions", options)
      this.setPositions(options.start, options.end)
    }

  }

  /**
   * update the TimeBar with start and end values. Input should be OfferTime objects
   * @param startOfferTime
   * @param endOfferTime
   */
  setPositions(startOfferTime, endOfferTime) {
    startOfferTime = startOfferTime.getValue()
    endOfferTime = endOfferTime.getValue()
    const minOfferTime = new OfferTime().setTime(this.options.min).getValue()
    const maxOfferTime = new OfferTime().setTime(this.options.max).getValue()

    const startPos = (startOfferTime - minOfferTime) / (maxOfferTime - minOfferTime) * 100
    const endPos = (endOfferTime - minOfferTime) / (maxOfferTime - minOfferTime) * 100

    //console.warn("setPositions", startPos, endPos)
    this.barEl.css({
      left: startPos + '%',
      right: (100 - endPos) + '%'
    })

  }

  getEl() {
    return this.el
  }
}
