//
//
//
//
//
//
//
//
//
//
//
//

import { Button } from '@resqclub/resq-components'
import Plus from 'vue-material-design-icons/Plus.vue'

export default {
	name: 'NewButton',
	props: [],
	data: () => ({}),
	computed: {},
	methods: {
		clicked() {
			this.$emit('click')
		},
	},
	components: {
		Button,
		Plus,
	},
}
