<template>
	<div class="InsightsPublishCell">
		<div v-if="!isPublishable" />
		<Button
			v-else-if="isPublished"
			@click="unpublish"
			class="Button unpublishButton"
			alt="Unpublish"
			>{{ lang.insights.publish.button.unpublish }}</Button
		>
		<Button
			v-else
			@click="showPublishConfirmationDialog"
			class="Button publishButton"
			alt="Publish"
			>{{ lang.insights.publish.button.publish }}</Button
		>
	</div>
</template>

<script>
import { Button } from '@resqclub/resq-components'
import vueStore from '../../es6/store'
export default {
	name: 'InsightsPublishCell',
	props: ['data'],
	data: () => ({
		lang: window.lang,
	}),
	computed: {
		isPublishable: function() {
			return this.data.isPublishable === 1
		},
		isPublished: function() {
			return this.data.isPublished === 1
		},
	},
	components: {
		Button,
	},
	methods: {
		publish(event) {
			postRequest('provider/setReviewPublishing/' + this.data.reviewId, {
				isPublished: true,
			})
				.then(() => {
					vueStore.dispatch('updateReviews')
				})
				.fail(err => {
					alert('Could not publish review')
					log('error', err)
				})
		},
		unpublish(event) {
			postRequest('provider/setReviewPublishing/' + this.data.reviewId, {
				isPublished: false,
			})
				.then(() => {
					vueStore.dispatch('updateReviews')
				})
				.fail(err => {
					alert('Could not unpublish review')
					log('error', err)
				})
		},
		showPublishConfirmationDialog(event) {
			let self = this
			let dlg = new ModalDialog(lang.insights.publish.dialog.title, { width: '700px' })

			dlg.add($('<p>').text(lang.insights.publish.dialog.text))
			dlg.add($('<p style="font-style: italic;">').text('"' + self.data.text + '"'))

			dlg.addToFooter(
				createBasicButton(
					lang.common.cancel,
					function() {
						dlg.hide()
					},
					'basicCancelButton modal-button'
				)
			)

			dlg.addToFooter(
				createBasicButton(
					lang.insights.publish.button.publish,
					function() {
						dlg.hide()
						self.publish()
					},
					'basicConfirmButton modal-button'
				)
			)

			dlg.show()
		},
	},
}
</script>

<style lang="stylus" scoped>
.unpublishButton {
	min-width: 90px;
	background: white;
	color: #16b599;
	border: 1px solid #16b599;
	outline: 2px;
}

.publishButton {
	min-width: 90px;
}
</style>
