//
//
//
//
//
//
//
//


import vueStore from '../../es6/store'

export default {
	name: "InsightsContextMenuItemDecoration",
	computed: {
		newReviewCount() {
			return vueStore.state.newReviewCount
		},
	}
}
