// this is not window.store (Persist library)
// This is vuex store. (SPARTAA)

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function getInitialState() {
    return {
        currentPage: null,
        currentRoute: null,
        unfetchedOrdersCount: 0,
        windowSize: {
            width: window.innerWidth,
            height: window.innerHeight
        },
        counter: 0,
        offerTemplates: null,
        offers: null,
        orders: null,
        reviews: null,
        newReviewCount: 0,
        connectionProblems: false,
        ableToPlayAudio: false, // true if browser allows playing of audio
        audioPlayingFailed: false // true if audio was supposed to play but failed
    }
}

export default new Vuex.Store({
    state: getInitialState,
    getters: {
        shouldShowIntercomWidget(state) {
            return state.windowSize.width >= 1050
        },
    },
    mutations: {
        x(state) {
            state.counter++
        },
        setRoute(state, { route, page }) {
            state.currentPage = page
            state.currentRoute = route
        },
        setWindowSize(state, { width, height }) {
            state.windowSize.width = width
            state.windowSize.height = height
        },
        setUnfetchedOrdersCount(state, count) {
            state.unfetchedOrdersCount = count
        },
        setOrders(state, orders) {
            state.orders = orders
        },
        setOffers(state, offers) {
            state.offers = offers
        },
        setOfferTemplates(state, offerTemplates) {
            state.offerTemplates = offerTemplates
        },
        setReviews(state, reviews) {
            state.reviews = reviews
        },
        setNewReviewCount(state, count) {
            state.newReviewCount = count
        },
        setConnectionProblems(state, problems) {
            state.connectionProblems = problems
        },
        setAbleToPlayAudio(state, data) {
            state.ableToPlayAudio = data
        },
        setAudioPlayingFailed(state, data) {
            state.audioPlayingFailed = data
        }
    },
    actions: {
        setRoute({ commit }, { route, page }) {
            commit('setRoute', { route, page })
        },
        updateOrders({ commit }) {
            window.getRequest('provider/orders').done(function(results) {
                var resultRows = JSON.parse(results).orders
                var unfetchedCount = resultRows.filter(row => !row.fetched && !row.refundReason).length
                commit('setUnfetchedOrdersCount', unfetchedCount)
                commit('setOrders', resultRows)
                commit('setConnectionProblems', false)
            }).fail(() => {
                commit('setConnectionProblems', true)
            })
        },
        updateOffers({ commit }) {
            window.getRequest('provider/offers').done(function(results) {
                var resultRows = JSON.parse(results).offers
                commit('setOffers', resultRows)
                commit('setConnectionProblems', false)
            }).fail(() => {
                commit('setConnectionProblems', true)
            })
        },
        updateOfferTemplates({ commit }) {
            window.getRequest('provider/offerTemplates').done(function(results) {
                var resultRows = JSON.parse(results).offerTemplates
                commit('setOfferTemplates', resultRows)
                commit('setConnectionProblems', false)
            }).fail(() => {
                commit('setConnectionProblems', true)
            })
        },
        updateReviews({ commit }) {
            window.getRequest('provider/reviews').done(response => {
                const reviews = JSON.parse(response)
                commit('setReviews', reviews)
            })
        }
    },
})
