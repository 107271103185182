import OfferTime from './offerTime'
export class TimeInput {
    constructor(options) {
        this.options = {
            step: 1000,
            inputChanged: function() {
            }
        }

        $.extend(this.options, options)

        this.validateInput = false
        this.el = $('<input>')
        this.el.attr({
            type: 'time',
            size: 5,
            name: this.options.name,
            id: this.options.name
        })

        var self = this

        this.oldValue = ''

        this.el.on('input', function(e) {
            if (!e.target.value.match(/^[\d _.,:-]*$/)) {
                console.log('invalid char. preventing default')
                // Prevent default doesn't work here (Safari atleast) so this is a trick to
                // simulate it.
                e.target.value = this.oldValue || ''
            }

            if (self.validateInput) {
                self.applyValue()
            }

            this.oldValue = e.target.value

            //console.warn("TimeInput changed", e)
        })

        this.el.on('blur', function(e) {
            //console.log("el value", self.el.val(), e)
            self.applyValue(true)
        })

        this.el.on('keyup', function(e) {
            //console.log("keyup", e)
            if (e.key === 'Enter') {
                self.el.blur()
            }

            if (e.key === 'Escape') {
                e.preventDefault()
                self.el.blur()
            }

        })

        /* turn validation on input changes on when using the arrow keys to advance the time.
		*  turn it back off allow typing the time before validation kicks in
		*/
        this.el.on('keydown', function(e) {
            //console.warn("keydown", e)
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                self.validateInput = true
            }
        })
        this.el.on('keyup', function(e) {
            //console.warn("keydown", e)
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                self.validateInput = false
            }
        })

        this.el.attr('step', this.options.step)
    }

    change(userInteraction) {
        return this.options.inputChanged(this, userInteraction)
    }
    trigger(eventName) {
    }
    applyValue(userInteraction = false) {
        var value = this.change(userInteraction)
        //console.warn("TimeInput changed", value)
        //console.log("TimeInput: input element " + this.options.name + " received input event. Value returned " + value)
        this.el.val(value)
    }

    get offerTime() {
        return new OfferTime().setTime(this.val)
    }

    get val() {
        return this.el.val()
    }

    set val(val) {
        this._val = val
        this.el.val(val)
    }

    getVal() {
        return this.val
    }

    set validate(input) {
        //console.warn("TimeInput.validate", input)
        this.el.val(input)
        this.applyValue()
        this.oldValue = input
        //this.el.trigger('input');
    }

    get date() {
        var inputDate = new Date()
        inputDate.setSeconds(0)
        inputDate.setMilliseconds(0)
        inputDate.setHours(this.val.substr(0, 2))
        inputDate.setMinutes(this.val.substr(3, 2))

        return inputDate
    }

    get name() {
        return this.options.name
    }

}

