import { TimeInput } from './timeInput.js'
import { TimeBar } from './timeBar.js'
import OfferTime from './offerTime'

export class TimeInputRange {
    constructor(options) {
        var self = this
        this.options = {
            step: 300,
            name: 'default',
            label: 'Default range',
            afterNow: false,
            showTimeBar: true
        }
        $.extend(this.options, options)

        this.inputChangedCallback = function(input, userInteraction) {
            if (self.options && self.options.inputChanged && typeof self.options.inputChanged === 'function') {
                return self.options.inputChanged(input, userInteraction)
            }
        }

        if (this.options.name === 'orderStart') {
            this.startInput = new TimeInput({
                inputChanged: this.inputChangedCallback,
                step: 300 * 6,
                name: this.options.name + 'Start'
            })
        } else {
            this.startInput = new TimeInput({
                inputChanged: this.inputChangedCallback,
                step: this.options.step,
                name: this.options.name + 'Start'
            })
        }

        this.endInput = new TimeInput({
            inputChanged: this.inputChangedCallback,
            step: this.options.step,
            name: this.options.name + 'End'
        })

        this.el = $('<div>').addClass('range-container')

        this.timeBar = new TimeBar({
            start: this.startInput.offerTime,
            end: this.endInput.offerTime
        })

        const rangeEl = $('<div>').addClass('range')

        this.el.append(rangeEl)
        this.el.append(this.timeBar.el)

        rangeEl.append($('<label>').text(this.options.label))

        var inputsEl = $('<div>').addClass('inputs')

        rangeEl.append($('<div>').addClass('spacer'))
        rangeEl.append(inputsEl)

        var startInputContainer = $('<div>').addClass('input-row')
        startInputContainer.append(this.startInput.el)
        startInputContainer.append(
            $('<label>').text(lang.p.dialog.orderpickuptimes.from).attr('for', this.options.name + 'Start'))
        var endInputContainer = $('<div>').addClass('input-row')

        endInputContainer.append(this.endInput.el)
        endInputContainer.append(
            $('<label>').text(lang.p.dialog.orderpickuptimes.to).attr('for', this.options.name + 'End'))

        inputsEl.append(startInputContainer)
        inputsEl.append(endInputContainer)

    }

    get inputs() {
        return [this.startInput, this.endInput]
    }

    setRange(start, end) {
        this.timeBar.setPositions(new OfferTime().setTime(start), new OfferTime().setTime(end))
    }
}
